var render = function render(){var _vm=this,_c=_vm._self._c;return _c('vxe-modal',_vm._g({staticClass:"dts-dialog",attrs:{"width":"360","position":_vm.position,"lockView":false,"mask":false},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},_vm.$listeners),[_c('span',{staticClass:"dts-dialog-title",attrs:{"slot":"title"},slot:"title"},[_c('i',{staticClass:"el-icon-location-outline"}),_c('div',[_vm._v("形象进度")])]),_c('div',{staticClass:"dts-dialog-body"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.tableDataLoading),expression:"tableDataLoading"}],ref:"multipleTable",staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"max-height":"300","header-cell-style":{
        background: 'rgba(40, 118, 193, .2)',
        color: 'white',
        border: 'rgb(255, 255, 255)',
        textAlign: 'center',
        fontSize: '15px',
        padding: '1',
      }}},[_c('el-table-column',{attrs:{"prop":"name","label":"名称"}}),_c('el-table-column',{attrs:{"prop":"unitName","label":"单位"}}),_c('el-table-column',{attrs:{"prop":"totalNum","label":"工程量"}}),_c('el-table-column',{attrs:{"prop":"progress","label":"施工进度","width":"100","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(_vm.formattedProgress(scope.row.progress))+" %")])]}}])})],1),_c('el-row',{staticStyle:{"margin-top":"5px"},attrs:{"gutter":2}},[_c('el-col',{attrs:{"span":4}},[_c('div',{staticStyle:{"color":"white","white-space":"nowrap"}},[_vm._v("图例：")])]),_c('el-col',{attrs:{"span":10}},[_c('div',{staticStyle:{"background-color":"#ebae0a","text-align":"center","color":"white"}},[_vm._v("已开工")])]),_c('el-col',{attrs:{"span":10}},[_c('div',{staticStyle:{"background-color":"#37ddeb","text-align":"center","color":"white","padding-left":"4px"}},[_vm._v("已完工")])])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }