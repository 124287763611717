<template>
  <div class="container">
    <div class="player" id="dts_player"></div>
    <!--底部导航栏-->
    <dts-navbar v-if="dtsNavbarVisible" ref="dtsNavbar" @select="toolbarSelectHandle"></dts-navbar>
    <!--右侧工具栏-->
    <dts-toolbar v-if="dtsToolbarVisible" ref="dtsToolbar" @select="toolbarSelectHandle"></dts-toolbar>
    <!--顶部工具栏-->
    <dts-toolbar-top v-if="dtsToolbarTopVisible" ref="dtsToolbarTop" @select="toolbarSelectHandle"></dts-toolbar-top>

    <!-- 项目选择（切换）组件 -->
    <dts-project-select v-if="dtsProjectSelectVisible" ref="dtsProjectSelect" @change="projectChange" :defaultPrjId="currentPrjId"></dts-project-select>
    
    <!-- 图层管理 -->
    <dts-layer-manager v-if="dtsLayerManagerVisible" ref="dtsLayerManager" 
      @click="wbsClickHandle" @dblclick="wbsDblclickHandle"
      @hide="closeLayerManagerHandle"
      @layerCheckChange="layerCheckChangeHandle"
      @layerDblClick="layerDblClickHandle">
    </dts-layer-manager>

    <!--形象进度查询组件-->
    <dts-visual-schedule-query v-if="dtsVisualScheduleQueryVisible" ref="dtsVisualScheduleQuery" @query="startVisualScheduleRendering" @hide="closeVisualScheduleQueryDialog"></dts-visual-schedule-query>
    
    <!--进度模拟组件-->
    <dts-schedule-simulation v-if="dtsScheduleSimulationVisible" ref="dtsScheduleSimulation" @startSimulation="startScheduleSimulation" @stopSimulation="stopScheduleSimulation" @hide="closeScheduleSimulationDialog"></dts-schedule-simulation>
    <!-- 构件属性组件 -->
    <dts-wbs-prop v-if="dtsWbsPropVisible" ref="dtsWbsProp" @hide="closeWbsPropHandle"></dts-wbs-prop>
    <!-- 天气选择组件 -->
    <dts-weather v-if="dtsWeatherVisible" ref="dtsWeather" @cloudChange="cloudChange" @rainChange="rainChange" @snowChange="snowChange" @reset="resetWeather"></dts-weather>
    <!-- 质量巡检工具栏 -->
    <dts-qutycheck-toolbar v-if="dtsQutyCheckToolbarVisible" ref="dtsQutyCheckToolbar" @stop="stopQutyCheckMarker" @hide="stopQutyCheckMarker"></dts-qutycheck-toolbar>
    <!-- 安全巡检工具栏 -->
    <dts-safetycheck-toolbar v-if="dtsSafetyCheckToolbarVisible" ref="dtsSafetyCheckToolbar" @stop="stopSafetyCheckMarker" @hide="stopSafetyCheckMarker"></dts-safetycheck-toolbar>
    <!--地形透明度-->
    <dts-terrain v-if="dtsTerrainVisible" ref="dtsTerrain" @terrainChange="terrainChange"></dts-terrain>
    
    <!-- 进度模拟同步显示的wbs面板 -->
    <dts-schedule-simulation-wbs v-if="dtsScheduleSimulationWbsVisible" ref="dtsScheduleSimulationWbs"></dts-schedule-simulation-wbs>
    <!-- 首页统计图组件 -->
    <dts-dashboard v-if="dtsDashboardVisible" ref="dtsDashboard"></dts-dashboard>
    <!-- 车载漫游 -->
    <dts-vehicle-roaming v-if="dtsVehicleRoamingVisible" ref="dtsVehicleRoaming" 
      @startRoaming="startVehicleRoaming" 
      @stopRoaming="stopVehicleRoaming" 
      @hide="closeVehicleRoaming" 
      @changeVehicleAssetVisible="changeVehicleAssetVisibleHandle"
      @changeVehicleRoamingPolylineVisible="changeVehicleRoamingPolylineVisibleHandle"
      @changeVisualScheduleVisible="changeVisualScheduleVisibleHandle"
      @changeSpeed="changeVehicleRoamingSpeedHandle"
      @pauseOrResume="pauseOrResumeHandle"></dts-vehicle-roaming>
    <!-- 视频监控窗口组件（仅支持最多同时打开3个） -->
    <dts-cctv v-if="cctv1Visible" ref="cctv1" @hide="closeCctvDialog(1)"></dts-cctv>
    <dts-cctv v-if="cctv2Visible" ref="cctv2" @hide="closeCctvDialog(2)"></dts-cctv>
    <dts-cctv v-if="cctv3Visible" ref="cctv3" @hide="closeCctvDialog(3)"></dts-cctv>

    <!--视频监控工具栏-->
    <dts-cctv-toolbar v-if="cctvToolbarVisible" ref="cctvToolbar" @stop="stopCctvMarker" @hide="stopCctvMarker"></dts-cctv-toolbar>
  </div>
</template>
<script>
  import {HashMap} from '@/utils'
  import DtsNavbar from './dts/dts-navbar'
  import DtsToolbar from './dts/dts-toolbar'
  import DtsToolbarTop from './dts/dts-toolbar-top'

  import DtsProjectSelect from './dts/dts-project-select'
  import DtsLayerManager from './dts/dts-layer-manager'
  import DtsVisualScheduleQuery from './dts/dts-visual-schedule-query'
  
  import DtsScheduleSimulation from './dts/dts-schedule-simulation'
  import DtsWbsProp from './dts/dts-wbs-prop'
  import DtsWeather from "./dts/dts-weather"
  import DtsTerrain from "./dts/dts-terrain"
  import DtsQutycheckToolbar from "./dts/dts-qutycheck-toolbar"
  import DtsSafetycheckToolbar from "./dts/dts-safetycheck-toolbar"
  
  import DtsScheduleSimulationWbs from './dts/dts-schedule-simulation-wbs'
  import Cookies from "js-cookie";
  import DtsDashboard from './dts/dts-dashboard'
  import DtsVehicleRoaming from './dts/dts-vehicle-roaming'
  import DtsCctv from './dts/dts-cctv'
  import DtsCctvToolbar from './dts/dts-cctv-toolbar'
  export default {
    data () {
      return {
        dtsNavbarVisible: false,
        dtsToolbarVisible: false,
        dtsToolbarTopVisible: false,

        dtsProjectSelectVisible: false, // 项目选择弹窗
        dtsWeatherVisible: false,
        dtsLayerManagerVisible: false,// 图层管理
        
        dtsScheduleSimulationWbsVisible: false,
        dtsDashboardVisible: false, // 统计图组件
        currentPrjId: '', // 当前选中的项目ID
        currentPrjName: '', // 当前选中的项目名称
        currentPrjZ: 0, // 当前项目的z值，用于确定动态创建marker时的高度
        currentWbs: '',// 当前选中的wbs
        options: {
          domId: 'dts_player',
          // 工程id
          pid: '1',
          reset: true,
          apiOptions: {
            // 注意：只有在onReady之后才可以调用AirCityAPI接口
            onReady: this.onReady,
            onLog: this.onLog,      // 日志输出回调函数
            onEvent: this.onEvent,   // 三维场景交互事件回调函数
            useColorLog: true, // 可选参数，日志颜色，默认关闭
          },
          ui: {
            startupInfo: false,          //默认true，初始化过程中是否显示详细信息
            statusIndicator: true,      //默认true，左上角闪烁的状态指示灯，可以从不同的颜色看出当前的状态
            statusButton: false,         //默认false，是否在左下角显示信息按钮
            fullscreenButton: false,     //默认false，是否在右下角显示全屏按钮
            homeButton: true,           //默认false，是否在左下角显示“回到初始位置”按钮
            taskListBar: 1,             //默认1，是否在下方显示“任务队列（API调用队列）”信息（0: 永不显示；1: 执行比较耗时的操作时显示；2: 一直显示）
            mainUI: false,               // 无默认值,是否显示Cloud工具栏(true:显示，false:隐藏)。可以通过settings.setMainUIVisibility设置
          },
          events: {
            onVideoLoaded: this.onLoaded,   // 视频流加载成功回调函数
            onConnClose: this.onClose,      // 连接断开回调函数
            mouseKeyListener: {         // 用于设置键盘、鼠标交互事件
              onMouseDown: this.onMouseDown,
              onKeyDown: this.onKeyDown
            }
          },

          showStatus: true,
          // 可选参数，是否显示页面加载详细信息，默认值为true
          showStartupInfo: false,

          // 可选参数，视频流加载成功回调函数
          // onloaded: onLoaded,

          // 可选参数，连接断开回调函数
          // onclose: onClose,

          // 可选参数，设置三维交互的键盘事件接收者
          // 注意：接收类型有视频标签(video)，网页文档(document)，空(none)
          keyEventTarget: 'video',
        },
        api: null,
        player: null,
        tileLayerCurSel: null, // 当前点选的TileLayer Actor
        tileLayerSelMap: new HashMap(), // 高亮的多个模型
        flagMap: new HashMap(), // 记录各种切换状态

        // 形象进度查询
        dtsVisualScheduleQueryVisible: false,
        // 临时创建自定义对象集合（用于形象进度渲染） key: customObjectId(自定义对象) value：{“layerId”，"objectId"}
        xxjdCustomObjectMap: new HashMap(),
        // 是否用户在运行形象进度渲染时手动关闭了形象进度组件面板（必须是用户手动关闭面板才认为是true）
        // dtsVisualScheduleQueryDialogManualClosed: false,

        // 进度模拟
        dtsScheduleSimulationVisible: false,
        scheduleSimulationTimer: '', // 进度模拟定时器ID
        scheduleSimulationDataMap: new HashMap(),// 进度模拟数据 （key：planEndDate，value:[{id:layerId,objectIds:['t1',...]}]）
        lastScheduleSimulationKeyIndex: -1, // 上次进度数据模拟高亮key的序号值
        isCurrentScheduleSimulationHighlight: false, // 当前进度数据是否正在高亮

        // 质量巡检marker
        qutyCheckMap: new HashMap(),
        dtsQutyCheckToolbarVisible: false,
        // 安全巡检marker
        safetyCheckMap: new HashMap(),
        dtsSafetyCheckToolbarVisible: false,

        // 构件属性
        dtsWbsPropVisible: false,
        measurementOptions: {
          'pointSize': 8.0,
          'textSize': 10.0,
          'textColor': Color.Yellow,
          'pointColor': [0, 0, 1, 0.3],
          'lineColor': Color.Blue,
          'areaColor': [0, 1, 0, 0.3],
          'showCoordinateText': true
        },
        // 上次鼠标左键按下的时间（毫秒数）
        lastLeftMouseButtonDownTime: 0,

        // 地形透明度
        dtsTerrainVisible: false,

        // 地形前缀 （用于区分图层树上地形模型,要求acp工程上地形名称已该值开头）
        terrainPrefix: '地形',
        // 倾斜摄影前缀 （用于区分图层树上倾斜摄影模型，要求acp工程上倾斜摄影名称已该值开头）
        osgbPrefix: '倾斜摄影',

        // 地形开关 （默认 显示地形）
        terrainDisplaySwitch: true,
        // 倾斜摄影开关 （默认 显示倾斜摄影）
        osgbDisplaySwitch: true,
         // 分屏浏览开关 （默认 关闭）
        multiViewPortSwitch: false,
        
        cctvMap: new HashMap(),

        // 车载漫游
        dtsVehicleRoamingVisible: false,
        showVehicleAsset: true, // 车载漫游时是否显示车辆载具
        showVehicleRoamingPolyline: true, // 车载漫游时是否显示箭头导引线
        vehicleRoamingSpeed: 1000, // 车载漫游车速
        pauseRoaming: false, // 车载漫游是否已暂停
        vehicleRoamingPolylineIds: [],// 车载漫游箭头导引线的ID
        airCityLoading: '',

        // 视频监控窗口组件（最多支持同时打开3个）
        cctv1Visible: false,
        cctv2Visible: false,
        cctv3Visible: false,
        // 视频监控(Marker)
        cctvToolbarVisible: false,
      }
    },
    components: {
      DtsNavbar,
      DtsToolbar,
      DtsToolbarTop,
      DtsProjectSelect,
      DtsLayerManager,
      DtsVisualScheduleQuery,
      
      DtsScheduleSimulation,
      DtsWbsProp,
      DtsWeather,
      DtsQutycheckToolbar,
      DtsSafetycheckToolbar,
      DtsTerrain,
      
      DtsScheduleSimulationWbs,
      DtsDashboard,
      DtsVehicleRoaming,
      DtsCctv,
      DtsCctvToolbar,
    },
    created () {
      this.airCityLoading = this.$loading({
        text: '',
        spinner: "aircity-loading",
        customClass: 'aircity-loading',
        background: 'rgba(0, 0, 0, 0.1)'
      })
      
      this.loadDefaultProjectAndMakeAirCityPlayer()// 加载并选择默认项目，同时创建AirCityPlayer对象
    },
    mounted () {
      document.title = "三维数字孪生平台"
    },
    destroyed () {
      // 关闭云渲染并释放资源
      // this.api.destroy()
    },
    methods: {
      onReady () {
        this.airCityLoading.close()

        // 要动态根据项目信息设置相机位置
        // this.api.camera.set(492035.37, 2488806.75, 402.62, -15.0, -173.0, 0.2)
        this.api.camera.get((r) => {
          // this.printLog('green',`相机位置: ${r.x}, ${r.y}, ${r.z}, ${r.pitch}, ${r.yaw}`)
        })

        // 开启事件监听
        this.api.settings.setEnableCameraMovingEvent(true)
        this.api.settings.setMousePickMask(7)
        
        // 显示首页的统计图组件
        this.openDashboard()
        // 显示底部菜单栏
        this.openNavbarDialog()
        // 显示右侧工具栏
        this.openToolbarDialog()
        // 显示顶部工具栏
        this.openToolbarTopDialog()
        
        // 创建各标注（如：视频监控点、环境监测点、三维巡检点、人员定位点等）
        this.addMarkers()
      },
      onEvent (event) {
        //事件类型 参考交互事件类型枚举对象
        var eventType = event.eventtype
        //图层类型
        var layerType = event.Type
        //图层Id
        var layerId = event.Id || event.ID
        //点击ActorId
        var objectId = event.ObjectID
        //当前点击位置
        var objectLocation = event.MouseClickPoint
        switch (eventType) {
          //鼠标左键点击时触发此事件
          case "LeftMouseButtonClick":
            // 鼠标按下时间和抬起时间差若超过180ms，则判断此次事件不是由单击事件触发，而是由鼠标拖动后的抬起触发，不执行左键单击事件逻辑
            if (new Date().getTime() - this.lastLeftMouseButtonDownTime > 180) {
              break;
            }
            // TODO 未解决缺陷：当选中高亮时稍微移动一下鼠标，对象的高亮就会失效。
            if (layerType == 'CustomObj' && layerId.startsWith("XXJD-")) {// 为了使得在形象进度渲染时也支持查看构件属性，此处要兼容CustomObj类型对象的点选事件
              let customObjectId = event.Id || event.ID
              let actorIdStr = customObjectId.replace("XXJD-", '')
              let actorIdArr = actorIdStr.split(',')
              if (actorIdArr && actorIdArr.length == 2) {
                layerId = actorIdArr[0]
                objectId = actorIdArr[1]
              }
              // 首先将双击wbs导致的高亮效果取消
              this.clearTileLayerSelMap()
              let mapKey = customObjectId
              if(this.tileLayerCurSel && this.tileLayerCurSel.customObjectId == customObjectId) {
                if (this.flagMap.get(mapKey)) {
                  this.flagMap.put(mapKey, false)
                  this.api.customObject.unhighlight(customObjectId)
                } else {
                  this.flagMap.put(mapKey, true)
                  this.api.customObject.highlight(customObjectId)
                }
              } else {
                if(this.tileLayerCurSel && this.tileLayerCurSel.customObjectId) {
                  // 去除上一个高亮的对象
                  this.api.customObject.unhighlight(this.tileLayerCurSel.customObjectId)
                  this.flagMap.put(this.tileLayerCurSel.customObjectId, false)
                }
                // 保存当前选中的对象
                this.tileLayerCurSel = {
                  'layerId': layerId,
                  'objectId': objectId,
                  'location': objectLocation,
                  'customObjectId': customObjectId
                }
                // 高亮本次选中的对象
                this.flagMap.put(mapKey, true)
                this.api.customObject.highlight(customObjectId)
                // 如果此时已经打开了构件属性面板，则需要同时刷新该面板的数据
                this.refreshWbsProp()
              }
            } else if (layerType == 'TileLayer') {
              // 首先将双击wbs导致的高亮效果取消
              this.clearTileLayerSelMap()
              let mapKey = layerId + "_" + objectId
              // 当前选中的模型是同一个则不重复高亮
              if(this.tileLayerCurSel && this.tileLayerCurSel.layerId == layerId && this.tileLayerCurSel.objectId == objectId) {
                if (this.flagMap.get(mapKey)) {
                  this.flagMap.put(mapKey, false)
                  this.api.tileLayer.stopHighlightActor(layerId, objectId)
                } else {
                  this.flagMap.put(mapKey, true)
                  this.api.tileLayer.highlightActor(layerId, objectId)
                }
              } else {
                if(this.tileLayerCurSel && this.tileLayerCurSel.layerId && this.tileLayerCurSel.objectId) {
                  // 去除上一个高亮的模型
                  this.api.tileLayer.stopHighlightActor(this.tileLayerCurSel.layerId, this.tileLayerCurSel.objectId)
                  this.flagMap.put(this.tileLayerCurSel.layerId + '_' + this.tileLayerCurSel.objectId, false)
                }
                // 保存当前选中的模型对象
                this.tileLayerCurSel = {
                  'layerId': layerId,
                  'objectId': objectId,
                  'location': objectLocation
                }
                // 设置本次点选模型高亮
                this.flagMap.put(mapKey, true)
                this.api.tileLayer.highlightActor(layerId, objectId)
                // 如果此时已经打开了构件属性面板，则需要同时刷新该面板的数据
                this.refreshWbsProp()
              }
            } else if (layerType == 'marker') {
              if (event.GroupID == 'CctvMarker') {// 打开视频监控画面组件
                this.openCctvDialog(event.UserData)
              }
            }
            break
          
          //imageButton点击事件
          case "UI_ImageButtonClick":
            // if (event.id === 'ProjectSelectImageButton') {// 打开项目选择弹窗
            //   this.printLog('red', '打开项目选择弹窗')
            //   this.openProjectChangeDialog()
            // } else if (event.id === 'WbsSelectImageButton') {// 打开wbs选择弹窗
            //   this.printLog('red', '打开wbs选择弹窗')
            //   this.openLayerManagerDialog()
            // }
            break

          //鼠标悬停时触发此事件
          //注意需提前开启鼠标拾取：this.api.settings.setMousePickMask(7)
          case "MouseHovered":
            // this.printLog('blue','鼠标悬停，eventType：' + eventType)
            break

          //鼠标移动时触发此事件
          //注意需提前开启鼠标拾取：this.api.settings.setMousePickMask(7)
          case "MouseMoved":
            // this.printLog('blue','鼠标移动，eventType：' + eventType)
            break

          //相机开始移动时触发此监听事件
          //注意需先开启事件：this.api.settings.setEnableCameraMovingEvent(true)
          case "CameraStartMove":
            // this.printLog('blue','相机开始飞行，eventType：' + eventType)
            break

          //相机正在移动时触发此监听事件
          //注意需先开启事件：this.api.settings.setEnableCameraMovingEvent(true)
          case "CameraMoving":
            // this.printLog('blue','相机正在飞行，eventType：' + eventType)
            break

          //相机停止移动时触发此监听事件
          //注意需先开启事件：this.api.settings.setEnableCameraMovingEvent(true)
          case "CameraStopMove":
            // this.printLog('blue','相机停止飞行，eventType：' + eventType)
            break

          //对象执行focus()或相机执行set()/lookAt()/lookAtBBox()方法时触发
          case "CameraChanged":
            // this.printLog('blue','相机位置发生变化，eventType：' + eventType)
            break

          //进入面剖切模式，编辑面剖切位置后触发事件并返回剖切结果
          case "PlaneClipEdit":
            // this.printLog('blue','编辑面剖切，eventType：' + eventType)
            break

          //进入体剖切模式，编辑体剖切位置后触发事件并返回剖切结果
          case "VolumeClipEdit":
            // this.printLog('blue','编辑体剖切，eventType：' + eventType)
            break

          //进入测量模式后，测量完成时触发此事件并返回测量结果
          case "Measurement":
            // this.printLog('blue','测量完成，eventType：' + eventType)
            break

          //播放导览结束触发此事件
          //this.api.camera.playAnimation(id)和导览对象播放导览结束this.api.cameraTour.play(id)均触发此事件
          case "CameraTourFinished":
            // this.printLog('blue','播放导览结束，eventType：' + eventType)
            break

          default:
            ""
        }
      },
      onLog (s,nnl) {
        // this.printLog('blue',`s：${s}`)
        // this.printLog('blue',`nnl：${nnl}`)
      },
      onLoaded () {
        this.printLog('green','视频流加载成功')
      },
      onClose () {
        this.printLog('red','连接断开')
      },
      onMouseDown (e) {
        // 1：左键，2：右键，4：滚轮
        if (e.buttons === 1) {
          this.lastLeftMouseButtonDownTime = new Date().getTime()
        } else if (e.buttons === 2) {
          // 打开contextmenu
        }
      },
      onKeyDown (e) {
        if (e && e.keyCode) {
          if (e.keyCode == 27) { // Esc键
            // 关闭[构件属性]
            this.closeWbsPropHandle()

            // 设置交互模式为漫游 默认值：0；【0：漫游，1：人物，2：无人机，3：中心漫游（物体观察），4：地图】
            this.api.settings.setInteractiveMode(0)
            // 关闭质量巡检已经打开的弹窗
            let qutyCheckKeys = this.qutyCheckMap.keys()
            if (qutyCheckKeys && qutyCheckKeys.length > 0) {
              this.api.marker.hidePopupWindow(qutyCheckKeys)
            }
            // 关闭安全巡检已经打开的弹窗
            let safetyCheckKeys = this.safetyCheckMap.keys()
            if (safetyCheckKeys && safetyCheckKeys.length > 0) {
              this.api.marker.hidePopupWindow(safetyCheckKeys)
            }
            // 关闭视频监控已经打开的弹窗
            let cctvKeys = this.cctvMap.keys()
            if (cctvKeys && cctvKeys.length > 0) {
              this.api.marker.hidePopupWindow(cctvKeys)
            }
            // 取消测量模式
            this.api.tools.stopMeasurement()
            // 停止面剖切
            this.api.tools.stopPlaneClip()
          }
        }
      },
      // 注意：所有日志输出均使用该方法
      printLog (color,text) {
        console.log(`%c${text}`, `color: ${color}`)
      },
      // 创建各marker标注
      addMarkers () {
        // 清空标注
        this.api.marker.clear()
        // TODO 涉及到的各类标注，应该是根据项目ID查询后台数据再创建
      },
      // 加载并选择默认项目，同时创建AirCityPlayer对象
      loadDefaultProjectAndMakeAirCityPlayer () {
        this.$http.get('/mps/project/openapi/briefsForMe').then(({data: res}) => {
          if (res.code === 0) {
            let projects = res.data
            if (!projects || projects.length == 0) {
              return this.$message({
                message: '未查询到授权的项目！',
                type: 'error',
                duration: 30000
              })
            }
            projects.forEach((p) => {
              if (p.dtsDefault == 1) {
                this.currentPrjId = p.id
                this.currentPrjName = p.name
                this.currentPrjZ = p.dtsZ
                this.options.pid = p.dtsPid
              }
            })
            // 如果所有项目都没设置默认，则选中第一个
            if (!this.currentPrjId || this.currentPrjId.length == 0) {
              this.currentPrjId = projects[0].id
              this.currentPrjName = projects[0].name
              this.currentPrjZ = projects[0].dtsZ
              this.options.pid = projects[0].dtsPid
            }
            // this.printLog('blue',`默认项目ID：${this.currentPrjId}，pid：${this.options.pid}`)
            // 创建AirCityPlayer对象
            this.player = new acapi.AirCityPlayer(`${window.SITE_CONFIG['dtsHost']}`,this.options)
            this.api = this.player.getAPI()
          }
        }).catch(() => {
          this.$message({
            message: '查询项目信息时出现错误，请重新登录系统。',
            type: 'error',
            duration: 30000
          })
        })
      },
      // 打开项目选择（切换）弹窗
      openProjectChangeDialog () {
        // 首先关闭所有已打开的窗口
        this.closeAllWindows()
        this.dtsProjectSelectVisible = true
        this.$nextTick(() => {
          this.$refs.dtsProjectSelect.init()
        })
      },
      // 项目切换
      projectChange (p) {
        // this.printLog('blue',`切换了项目，id：${p.id}，name：${p.name}`)
        this.currentPrjId = p.id
        this.currentPrjName = p.name
        this.currentPrjZ = p.dtsZ
        this.options.pid = p.dtsPid // 当前工程ID切换为最新的项目ID
        // 关闭所有打开的组件
        this.dtsProjectSelectVisible = false
        this.dtsLayerManagerVisible = false
        this.dtsVisualScheduleQueryVisible = false
        this.dtsScheduleSimulationVisible = false
        this.dtsWeatherVisible = false
        this.dtsTerrainVisible = false

        // 默认显示地形，倾斜摄影
        this.terrainDisplaySwitch = true
        this.osgbDisplaySwitch = true
        // 默认 关闭分屏浏览
        this.multiViewPortSwitch=false

        this.api.destroy()
        this.player = new acapi.AirCityPlayer(`${window.SITE_CONFIG['dtsHost']}`, this.options)
        this.api = this.player.getAPI()
        // 切换项目时刷新统计图组件
        if (this.dtsDashboardVisible) {
          this.$refs.dtsDashboard.init(this.currentPrjId, this.currentPrjName)
        }
        // 切换项目时刷新车载漫游组件中的车载路线
        if (this.dtsVehicleRoamingVisible) {
          this.$refs.dtsVehicleRoaming.init(this.currentPrjId)
        }
      },
      // 显示底部菜单栏
      openNavbarDialog () {
        this.dtsNavbarVisible = true
        this.$nextTick(() => {
          this.$refs.dtsNavbar.init()
        })
      },
      // 显示右侧工具栏
      openToolbarDialog () {
        this.dtsToolbarVisible = true
        this.$nextTick(() => {
          this.$refs.dtsToolbar.init()
        })
      },
      // 显示顶部工具栏
      openToolbarTopDialog () {
        this.dtsToolbarTopVisible = true
        this.$nextTick(() => {
          this.$refs.dtsToolbarTop.init()
        })
      },
      // 打开图层管理
      async openLayerManagerDialog () {
        // 关闭[项目切换窗口]
        this.dtsProjectSelectVisible = false
		    // 关闭[统计图表看板]
        this.closeDashboard()
        
        let terrainArr = []
        let osgbArr = []
        let bimArr = []
        let checkedKeys = []
        let infoTree = await this.api.infoTree.get()
        let treeDataList = infoTree.infotree
        if (treeDataList && treeDataList.length > 0) {
          for (let i = 0; i < treeDataList.length; i++) {
            let treeData = treeDataList[i]
            let type = treeData.type
            let name = treeData.name
            if (('EPT_Scene' == type) && name) {
              if (name.indexOf(this.terrainPrefix) != -1) {
                // 地形
                terrainArr.push({
                  id: treeData.iD,
                  label: name
                })
              } else if (name.indexOf(this.osgbPrefix) != -1) {
                // 倾斜摄影
                osgbArr.push({
                  id: treeData.iD,
                  label: name
                })
              } else {
                // 模型
                bimArr.push({
                  id: treeData.iD,
                  label: name
                })
              }

              checkedKeys.push(treeData.iD)
            }
          }
        }

        let layerArr = [
          {
            id: 'terrain',
            label: '地形',
            children: terrainArr
          },
          {
            id: 'osgb',
            label: '倾斜摄影',
            children: osgbArr
          },
          {
            id: 'bim',
            label: '模型',
            children: bimArr
          }
        ]
        checkedKeys.push('terrain')
        checkedKeys.push('osgb')
        checkedKeys.push('bim')

        this.dtsLayerManagerVisible = true
        this.$nextTick(() => {
          this.$refs.dtsLayerManager.init(this.currentPrjId, layerArr, checkedKeys)
        })
      },
      // 关闭图层管理
      closeLayerManagerHandle() {
        this.dtsLayerManagerVisible = false
      },
      // 勾选/反选图层
      async layerCheckChangeHandle(checkedIds, unCheckedIds) {
        await this.api.infoTree.hide(unCheckedIds)
        await this.api.infoTree.show(checkedIds)
      },
      // 双击图层定位
      layerDblClickHandle(layerId) {
        this.api.infoTree.focus(layerId)
      },
      // 选中wbs
      wbsClickHandle (wbs) {
        this.currentWbs = wbs
        // 暂时对wbs的单击不做任何事件处理
      },
      // 双击wbs
      wbsDblclickHandle (wbs) {
        this.currentWbs = wbs
        // 通过wsbId 定位、高亮模型
        // 首先，清空上一次双击导致的高亮
        this.clearTileLayerSelMap()
        const loading = this.$loading({
          lock: true,
          text: '正在查询已挂接的模型信息...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })
        this.$http.get(`/opm/dtsWbs/list/${this.currentWbs.id}?prjId=${this.currentPrjId}`).then(({data: res}) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg)
          }
          if (res.data.length > 0) {
            // 关闭用户点选高亮的模型
            this.clearTileLayerCurSel()
            // 随机定位一个模型
            this.api.tileLayer.focusActor(res.data[0].layerId,res.data[0].objectId)
            for (let i = 0; i < res.data.length; i++) {
              // 高亮模型
              let layerId = res.data[i].layerId
              let objectId = res.data[i].objectId
              this.api.tileLayer.highlightActor(layerId,objectId)
              let mapKey = layerId + "_" + objectId
              // 将高亮的模型信息保存到该集合中
              this.tileLayerSelMap.put(mapKey, {
                'layerId': layerId,
                'objectId': objectId,
              })
            }
            let layerId = res.data[0].layerId
            let objectId = res.data[0].objectId
            let mapKey = layerId + "_" + objectId
            // 随便一个与之关联的模型作为当前点选的模型
            this.tileLayerCurSel = {
              'layerId': layerId,
              'objectId': objectId,
            }
            this.flagMap.put(mapKey, true)
          } else {
            this.$message({
              message: '未查询到关联的模型',
              type: 'warning',
              duration: 1000
            })
          }
        }).catch(() => {
          return this.$message.error('出错了')
        }).finally(() => {
          loading.close()
        })
      },
      // 清空上一次双击导致的高亮模型
      clearTileLayerSelMap () {
        if (this.tileLayerSelMap.size() > 0) {
          let arr = this.tileLayerSelMap.values()
          for (const o of arr) {
            // 取消模型高亮
            if (o.layerId && o.objectId) {
              this.api.tileLayer.stopHighlightActor(o.layerId, o.objectId)
            }
            // 取消自定义对象高亮
            if (o.customObjectId) {
              this.api.customObject.unhighlight(o.customObjectId)
            }
          }
          this.tileLayerSelMap.clear()
        }
      },
      // 清除用户上一次点选导致的高亮模型
      clearTileLayerCurSel () {
        if (this.tileLayerCurSel && this.tileLayerCurSel.layerId && this.tileLayerCurSel.objectId) {
          this.api.tileLayer.stopHighlightActor(this.tileLayerCurSel.layerId,this.tileLayerCurSel.objectId)
        }
      },
      /**
       * 获取图层树上模型图层的ID集合
       * @param isIncludeBIM 是否获取BIM模型
       * @param isIncludeTerrain 是否获取地形
       * @param isIncludeBIM 是否获取倾斜摄影
      */
      async getTileLayerIdList (isIncludeBIM, isIncludeTerrain, isIncludeOSGB) {
        let tileLayerIdList = []
        let includeBIM = isIncludeBIM || false
        let includeTerrain = isIncludeTerrain || false
        let includeOSGB = isIncludeOSGB || false
        if(includeBIM || includeTerrain || includeOSGB) {
          let infoTree = await this.api.infoTree.get()
          let treeDataList = infoTree.infotree
          if (treeDataList && treeDataList.length > 0) {
            for (let i = 0; i < treeDataList.length; i++) {
              let treeData = treeDataList[i]
              let type = treeData.type
              let name = treeData.name
              if (type && type == 'EPT_Scene' && name) {
                if (name.indexOf(this.terrainPrefix) != -1) {
                    if(isIncludeTerrain){
                      tileLayerIdList.push(treeData.iD)
                    }
                } else if (name.indexOf(this.osgbPrefix) != -1) {
                  if(includeOSGB){
                    tileLayerIdList.push(treeData.iD)
                  }
                } else {
                  if(includeBIM){
                    tileLayerIdList.push(treeData.iD)
                  }
                }
              }
            }
          }
        }
        return tileLayerIdList
      },
      // 打开形象进度查询弹窗
      async openVisualScheduleQueryDialog () {
        // 首先关闭所有已打开的窗口
        await this.closeAllWindows()
        this.dtsVisualScheduleQueryVisible = true
        // this.dtsVisualScheduleQueryDialogManualClosed = false
        this.$nextTick(() => {
          this.$refs.dtsVisualScheduleQuery.init(this.currentPrjId)
        })
      },
      // 关闭形象进度查询弹窗
      closeVisualScheduleQueryDialog () {
        // this.printLog('green',`关闭形象进度查询弹窗`)
        this.clearVisualScheduleRendering()
        this.dtsVisualScheduleQueryVisible = false
        // this.dtsVisualScheduleQueryDialogManualClosed = true
      },
      // 开始形象进度渲染
      async startVisualScheduleRendering (autoFocus=true) {
        // this.printLog('green',`开始形象进度渲染：`)
        const loading = this.$loading({
          lock: true,
          text: '正在查询进度数据并进行模型运算...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        // 为了防止遮罩层一直显示，导致用户页面无法继续操作，暂时通过定时器的保险措施
        // window.setTimeout(()=> {
        //   loading.close()
        // }, 20000)

        // 清除上一次形象进度渲染
        await this.clearVisualScheduleRendering()
        // 根据项目ID，后台查询出形象进度渲染数据集合
        let {data: res} = await this.$http.get(`/opm/dtsWbs/visualSchedule/data`, {
          params: {
            prjId: this.currentPrjId,
          }
        })

        if (res.code !== 0) {
          loading.close()
          return this.$message({
            message: '查询形象进度数据时出现错误！',
            type: 'error',
            duration: 5000,
          })
        }
        if (res.data && res.data.length > 0) {
          let dataMap = new HashMap()// key：颜色，value：[{layerId: '', objectId: ''}]
          let colorMap = new HashMap()
          for (let i = 0; i < res.data.length; i++) {
            /**
             data 数据结构示例：
              [{
                "layerId":"1", // 模型图层(TileLayer) 信息
                "objectId":'Actor_01', // 模型图层(TileLayer) 中模型Actor的ID
                "wbsId":"wbs_01", // 工程部位ID
                // 未开工：null
                "visualScheduleColor": [1, 1, 1, 0.5]
              }]
            */
            let data = res.data[i]
            if (data) {
              let color = data['visualScheduleColor']
              if (data['layerId'] && data['objectId'] && color) {
                let mapVal = dataMap.get(color)
                if (!mapVal) {
                  mapVal = []
                }
                mapVal.push(data)
                dataMap.put(color, mapVal)
              }
              colorMap.put(color, color)
            }
          }

          if (dataMap.size() > 0) {
            // 按颜色分组循环解析
            let colorKeys = dataMap.keys()
            for (let i = 0; i < colorKeys.length; i++) {
              // 判断用户是否主动关闭了形象进度组件面板
              // if (this.dtsVisualScheduleQueryDialogManualClosed) {
              //   loading.close()
              //   break
              // }
              let color = colorKeys[i]
              let dataArr = dataMap.get(color)
              if (dataArr && dataArr.length > 0) {
                let customObjects = []
                let customObjectIds = []
                let hideActors = []
                for (let j = 0; j < dataArr.length; j++) {
                  let _layerId = dataArr[j]["layerId"]
                  let _objectId = dataArr[j]["objectId"]
                  // 用 new Date().getTime()，毫秒也会重复
                  let customObjectId = "XXJD-" + _layerId + ',' + _objectId

                  customObjects.push({
                    id: customObjectId,
                    tileLayerId: _layerId,
                    objectId: _objectId,
                    coordinateType: 0,
                    smoothMotion: 0, // 1: 平滑插值，0: 跳跃
                  })

                  customObjectIds.push(customObjectId)

                  hideActors.push({
                    id: _layerId,
                    objectIds: _objectId,
                  })

                  this.xxjdCustomObjectMap.put(customObjectId, {layerId: _layerId, objectId: _objectId})
                }
                
                if (customObjects.length > 0) {
                  // 批量创建自定义对象
                  await this.api.customObject.addByTileLayer(customObjects)
                  // 批量设置颜色
                  await this.api.customObject.setTintColor(customObjectIds, colorMap.get(color))

                  // 隐藏图层对象的模型
                  await this.api.tileLayer.hideActors(hideActors)

                  if (autoFocus && i == 0) {// 定位
                    await this.api.customObject.focus(customObjectIds[0])
                  }
                }
              }
            }
          }
          
          // 查询图层树(InfoTree)上模型图层对象(TileLayer)
          let tileLayerIdList = await this.getTileLayerIdList(true, false, false)
          // 设置模型图层对象(TileLayer)的所有模型（未开工状态）样式为白色透明。
          let layerStyleParam = {
            ids: tileLayerIdList, // Layer的ID或ID数组
            style: 1, // 0：默认；1：X光；2：纯色；3：水晶体；4：暗黑；5：科幻；6：扩散
            color: [1, 1, 1, 0.1], // 颜色(r,g,b,a) 白色透明。默认值:Color.White，支持四种格式
          }
          await this.api.tileLayer.setStyle(layerStyleParam.ids, layerStyleParam.style, layerStyleParam.color)
          loading.close()
        } else {
          loading.close()
           this.$message({
            message: '未查询到相关数据',
            type: 'error',
            duration: 5000,
          })
        }
      },
      // 清除形象进度渲染
      async clearVisualScheduleRendering () {
        // 查询图层树(InfoTree)上模型图层对象(TileLayer)
        let tileLayerIdList= await this.getTileLayerIdList(true,false,false)
        // 还原模型图层对象(TileLayer)的所有模型样式为初始状态。
        let layerStyleParam = {
          ids: tileLayerIdList, // Layer的ID或ID数组
          style: 0, // 0：默认；1：X光；2：纯色；3：水晶体；4：暗黑；5：科幻；6：扩散
          color: Color.White // 颜色 默认值:Color.White，
        }
        this.api.tileLayer.setStyle(layerStyleParam.ids, layerStyleParam.style, layerStyleParam.color)

        if (this.xxjdCustomObjectMap && this.xxjdCustomObjectMap.size() > 0) {
          let customObjectIdList = []
          let layerIdList = []
          let keys = this.xxjdCustomObjectMap.keys()
          for (let i = 0; i < keys.length; i++) {
            let val = this.xxjdCustomObjectMap.get(keys[i])
            customObjectIdList.push(keys[i])
            if (val && val.layerId) {
              layerIdList.push(val.layerId)
            }
          }
          // 删除 自定义对象（CustomObject）
          if (customObjectIdList && customObjectIdList.length > 0) {
            await this.api.customObject.delete(customObjectIdList)
          }
          // 显示指定图层对象（tilelayer）的所有模型（Actor）
          if (layerIdList && layerIdList.length > 0) {
            for (let i = 0; i < layerIdList.length; i++) {
              this.api.tileLayer.showAllActors(layerIdList[i])
            }
          }
          // 清空缓存形象进度数据集合
          this.xxjdCustomObjectMap.clear()
        }
      },
      // 打开进度模拟弹窗
      openScheduleSimulationDialog () {
        // 首先关闭所有已打开的窗口
        this.closeAllWindows()
        this.dtsScheduleSimulationVisible = true
        this.$nextTick(() => {
          this.$refs.dtsScheduleSimulation.prjId = this.currentPrjId
          this.$refs.dtsScheduleSimulation.init()
        })
      },
      // 关闭进度模拟弹窗
      closeScheduleSimulationDialog () {
        // this.printLog("green",'关闭进度模拟弹窗')
        this.dtsScheduleSimulationVisible = false
        this.dtsScheduleSimulationWbsVisible = false
        this.stopScheduleSimulation()
        //停止所有模型Actor高亮
        this.api.tileLayer.stopHighlightAllActors().then(() => {
          // 获取模型图层id集合
          this.getTileLayerIdList(true,false,false).then((tileLayerIdList) => {
            if (tileLayerIdList && tileLayerIdList.length > 0) {
              for (let i = 0; i < tileLayerIdList.length; i++) {
                // 显示当前TileLayer的所有模型Actor
                // todo 待测试，由于调用TileLayer的hideAllActors隐藏所有模型Actor的方法由于Actor数据量大报错，所以这里显示方法暂时无法测试性能
                this.api.tileLayer.showAllActors(tileLayerIdList[i])
              }
            }
          }).catch(err => {
            //  do nothing
          })
        }).catch(err => {
          //  do nothing
        })
      },

      // arr是一个数组，每个元素包括id、objectIds属性，objectIds是一个数组，如果objectIds长度大于20，则截取前20个元素
      // 返回处理后的新数组，不修改原数组
      // simplifyObjects (arr) {
      //   if (!arr || arr.length === 0) {
      //     return []
      //   }
        
      //   // 深拷贝原数组
      //   let newArr = JSON.parse(JSON.stringify(arr))
        
      //   for (let i = 0; i < newArr.length; i++) {
      //     let item = newArr[i]
      //     if (item && item.objectIds && item.objectIds.length > 40) {
      //       item.objectIds = item.objectIds.slice(0, 40)
      //     }
      //   }
        
      //   return newArr
      // },

      // 启动进度模拟
      startScheduleSimulation (data) {
        // this.printLog('green','启动进度模拟')
        const loading = this.$loading({
          lock: true,
          text: '正在查询进度计划并启动进度模拟...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        // 先停止进度模拟
        this.stopScheduleSimulation()

        // 停止所有模型Actor高亮
        this.api.tileLayer.stopHighlightAllActors().then(() => {
          // 获取模型图层id集合
          this.getTileLayerIdList(true,false,false).then((tileLayerIdList) => {
            if (tileLayerIdList && tileLayerIdList.length > 0) {
              // 隐藏指定图层tilelayer的所有模型Actor
              this.api.tileLayer.hideAllActors(tileLayerIdList)
              // 根据项目ID,日期范围，后台查询出进度模拟数据集合
              this.$http.get(`/opm/dtsWbs/scheduleSimulation/data`,
                {
                  params: {
                    prjId: this.currentPrjId,
                    startDate: data.startDate,
                    endDate: data.endDate,
                  }
                }).then(({data: res}) => {
                  if (res.code === 0) {
                    if (res.data && res.data.length > 0) {
                      /**
                        data 数据结构示例：(按进度计划结束日期正序排序)
                        [{
                          "layerId": "1", // 模型图层(TileLayer) 信息
                          "objectId":'Actor_01', // 模型图层(TileLayer) 中模型Actor的ID
                          "wbsId": "wbs_01", // 业务数据：工程部位ID
                          "planStartDate": '2023-01-02' // 进度计划开始日期
                          "planEndDate": '2023-01-03' // 进度计划结束日期
                          "output": 0 // 产值
                        }]
                      */
                      // 根据周计划数据，按天分组显示模型
                      let dataMap = new HashMap() // key: planEndDate，value:（key: layerId，value:[objectId]）
                      let wbsNameMap = new HashMap() // key：planEndDate，value：[wbs名称的数组]
                      let outputMap = new HashMap() // key：planEndDate，value：产值开累
                      let outputTotal = 0;
                      for (let i = 0; i < res.data.length; i++) {
                        let data = res.data[i]
                        if (data) {
                          let wbsName = data['wbsName']
                          let layerId = data['layerId']
                          let objectId = data['objectId']
                          let planEndDate = data['planEndDate']
                          // let output = data['output']
                          let output = 50 // TODO 暂时使用模拟数据
                          if (layerId && objectId && planEndDate) {
                            let layerMap = dataMap.get(planEndDate)
                            if (!layerMap) {
                              layerMap = new HashMap()
                            }
                            let objectIds = layerMap.get(layerId)
                            if (!objectIds) {
                              objectIds = []
                            }
                            objectIds.push(objectId)
                            layerMap.put(layerId, objectIds)
                            dataMap.put(planEndDate, layerMap)
                            
                            // 缓存进度模拟过程中每个日期对应的wbs名称（数组）
                            let wbsNameArr = wbsNameMap.get(planEndDate)
                            if (!wbsNameArr) {
                              wbsNameArr = []
                            }
                            // 避免wbs名称重复
                            if (wbsNameArr.indexOf(wbsName) == -1) {
                              wbsNameArr.push(wbsName)
                            }
                            wbsNameMap.put(planEndDate, wbsNameArr)

                            // 进度模拟时各日期累计的产值
                            outputTotal += output
                            outputMap.put(planEndDate, outputTotal)
                          }
                        }
                      }

                      if (dataMap.size() > 0) {
                        let planEndDateList = dataMap.keys()
                        for (let i = 0; i < planEndDateList.length; i++) {
                          let layerDataList = []
                          let layerMap = dataMap.get(planEndDateList[i])
                          let layerIdList = layerMap.keys()
                          for (let j = 0; j < layerIdList.length; j++) {
                            // 拼接模型图层高亮所需要的数据结构
                            layerDataList.push({id: layerIdList[j], objectIds: layerMap.get(layerIdList[j])})
                          }
                          this.scheduleSimulationDataMap.put(planEndDateList[i], layerDataList)
                        }
                      }
                      if (this.scheduleSimulationDataMap && this.scheduleSimulationDataMap.size() > 0) {
                        // 同时在左侧显示模型对应的分部分项工程
                        this.dtsScheduleSimulationWbsVisible = true
                        this.$nextTick(() => {
                          this.$refs.dtsScheduleSimulationWbs.init()
                        })
                        loading.close()
                        // 这里采用setInterval循环定时执行 模型高亮、定位
                        this.scheduleSimulationTimer = setInterval(() => {
                          if (!this.isCurrentScheduleSimulationHighlight) {
                            this.isCurrentScheduleSimulationHighlight = true
                            if (this.lastScheduleSimulationKeyIndex >= 0) {
                              if (this.lastScheduleSimulationKeyIndex < this.scheduleSimulationDataMap.keys().length) {
                                let lastLayerList = this.scheduleSimulationDataMap.get(this.scheduleSimulationDataMap.keys()[this.lastScheduleSimulationKeyIndex-1])
                                let layerList = this.scheduleSimulationDataMap.get(this.scheduleSimulationDataMap.keys()[this.lastScheduleSimulationKeyIndex])
                                // 队列阻塞问题，每组数据量限制为20？
                                // lastLayerList = this.simplifyObjects(lastLayerList)
                                // layerList = this.simplifyObjects(layerList)

                                // 该方法会导致出现队列阻塞问题，暂时不需要反高亮了
                                //this.api.tileLayer.stopHighlightActors(lastLayerList, null)
                                // 显示图层中的模型
                                this.api.tileLayer.showActors(layerList, null)
                                // 高亮
                                this.api.tileLayer.highlightActors(layerList, null)
                                // 动态更新分部分项工程面板
                                if (this.dtsScheduleSimulationWbsVisible) {
                                  let thisPlanEndDate = this.scheduleSimulationDataMap.keys()[this.lastScheduleSimulationKeyIndex]
                                  this.$refs.dtsScheduleSimulationWbs.showWbs(thisPlanEndDate, wbsNameMap.get(thisPlanEndDate), outputMap.get(thisPlanEndDate))
                                }
                                // 定位某一个图层上的一个模型
                                this.api.tileLayer.focusActors(layerList[0], 25, 1, [-30,90,0], null)
                                this.lastScheduleSimulationKeyIndex++
                                this.isCurrentScheduleSimulationHighlight = false
                              } else {
                                // 结束进度模拟
                                this.$refs.dtsScheduleSimulation.stopSimulation()
                                loading.close()
                                this.printLog('green', "进度模拟渲染结束")
                              }
                            } else {
                              this.lastScheduleSimulationKeyIndex = 0
                              let layerList = this.scheduleSimulationDataMap.get(this.scheduleSimulationDataMap.keys()[0])
                              // layerList = this.simplifyObjects(layerList)
                              // 显示图层中的模型
                              this.api.tileLayer.showActors(layerList, null)
                              // 高亮
                              this.api.tileLayer.highlightActors(layerList, null)
                              // 左上角显示wbs名称
                              if (this.dtsScheduleSimulationWbsVisible) {
                                let thisPlanEndDate = this.scheduleSimulationDataMap.keys()[0]
                                this.$refs.dtsScheduleSimulationWbs.showWbs(thisPlanEndDate, wbsNameMap.get(thisPlanEndDate), 0, 0, 0)
                              }
                              // 定位某一个图层上的多个模型
                              this.api.tileLayer.focusActors(layerList[0], 25, 1, [-30,90,0], null)
                              this.lastScheduleSimulationKeyIndex++
                              this.isCurrentScheduleSimulationHighlight = false
                            }
                          }
                        }, 2000)
                      } else {
                        loading.close()
                        // 结束进度模拟
                        this.$refs.dtsScheduleSimulation.stopSimulation()
                        this.printLog('green', "进度模拟渲染结束")
                      }
                    } else {
                      loading.close()
                      this.$message({
                        message: '该时间段内，未查询进度计划关联的模型！',
                        type: 'warning',
                        duration: 3000
                      })
                      // 结束进度模拟
                      this.$refs.dtsScheduleSimulation.stopSimulation()
                      this.printLog('green', "进度模拟渲染结束")
                    }
                  } else {
                    loading.close()
                    this.$message({
                      message: '查询进度模拟所挂接的模型构件记录时出现错误，res.code：' + res.code,
                      type: 'error',
                      duration: 5000
                    })
                    // 结束进度模拟
                    this.$refs.dtsScheduleSimulation.stopSimulation()
                    this.printLog('green', "进度模拟渲染结束")
                  }
                }).catch(() => {
                  loading.close()
                  this.$message({
                    message: '查询进度模拟所挂接的模型构件记录时出现错误，请稍后重试。',
                    type: 'error',
                    duration: 5000
                  })
                  // 结束进度模拟
                  this.$refs.dtsScheduleSimulation.stopSimulation()
                  this.printLog('green', "进度模拟渲染结束")
                })
              }
            }).catch(err => {
              loading.close()
              this.printLog('red', 'getTileLayerIdList-error:'+err)
            })
          }).catch(err => {
            loading.close()
            this.printLog('red', 'stopHighlightAllActors-error:'+err)
          })
      },
      // 启动进度模拟（链式调用的方法备份）
      startScheduleSimulation_backup (data) {
        // this.printLog('green','启动进度模拟')
        const loading = this.$loading({
          lock: true,
          text: '正在查询进度计划并启动进度模拟...',
          spinner: 'el-icon-loading',
          customClass: 'my-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        })

        // 先停止进度模拟
        this.stopScheduleSimulation()

        // 停止所有模型Actor高亮
        this.api.tileLayer.stopHighlightAllActors().then(() => {
          // 获取模型图层id集合
          this.getTileLayerIdList(true,false,false).then((tileLayerIdList) => {
            if (tileLayerIdList && tileLayerIdList.length > 0) {
              // 隐藏指定图层tilelayer的所有模型Actor
              this.api.tileLayer.hideAllActors(tileLayerIdList)
              // 根据项目ID,日期范围，后台查询出进度模拟数据集合
              this.$http.get(`/opm/dtsWbs/scheduleSimulation/data`,
                {
                  params: {
                    prjId: this.currentPrjId,
                    startDate: data.startDate,
                    endDate: data.endDate,
                  }
                }).then(({data: res}) => {
                  if (res.code === 0) {
                    if (res.data && res.data.length > 0) {
                      /**
                        data 数据结构示例：(按进度计划结束日期正序排序)
                        [{
                          "layerId": "1", // 模型图层(TileLayer) 信息
                          "objectId":'Actor_01', // 模型图层(TileLayer) 中模型Actor的ID
                          "wbsId": "wbs_01", // 业务数据：工程部位ID
                          "planStartDate": '2023-01-02' // 进度计划开始日期
                          "planEndDate": '2023-01-03' // 进度计划结束日期
                          "output": 0 // 产值
                        }]
                      */
                      // 根据周计划数据，按天分组显示模型
                      let dataMap = new HashMap() // key: planEndDate，value:（key: layerId，value:[objectId]）
                      let wbsNameMap = new HashMap() // key：planEndDate，value：[wbs名称的数组]
                      let outputMap = new HashMap() // key：planEndDate，value：产值开累
                      let outputTotal = 0;
                      for (let i = 0; i < res.data.length; i++) {
                        let data = res.data[i]
                        if (data) {
                          let wbsName = data['wbsName']
                          let layerId = data['layerId']
                          let objectId = data['objectId']
                          let planEndDate = data['planEndDate']
                          // let output = data['output']
                          let output = 50 // TODO 暂时使用模拟数据
                          if (layerId && objectId && planEndDate) {
                            let layerMap = dataMap.get(planEndDate)
                            if (!layerMap) {
                              layerMap = new HashMap()
                            }
                            let objectIds = layerMap.get(layerId)
                            if (!objectIds) {
                              objectIds = []
                            }
                            objectIds.push(objectId)
                            layerMap.put(layerId, objectIds)
                            dataMap.put(planEndDate, layerMap)
                            
                            // 缓存进度模拟过程中每个日期对应的wbs名称（数组）
                            let wbsNameArr = wbsNameMap.get(planEndDate)
                            if (!wbsNameArr) {
                              wbsNameArr = []
                            }
                            // 避免wbs名称重复
                            if (wbsNameArr.indexOf(wbsName) == -1) {
                              wbsNameArr.push(wbsName)
                            }
                            wbsNameMap.put(planEndDate, wbsNameArr)

                            // 进度模拟时各日期累计的产值
                            outputTotal += output
                            outputMap.put(planEndDate, outputTotal)
                          }
                        }
                      }

                      if (dataMap.size() > 0) {
                        let planEndDateList = dataMap.keys()
                        for (let i = 0; i < planEndDateList.length; i++) {
                          let layerDataList = []
                          let layerMap = dataMap.get(planEndDateList[i])
                          let layerIdList = layerMap.keys()
                          for (let j = 0; j < layerIdList.length; j++) {
                            // 拼接模型图层高亮所需要的数据结构
                            layerDataList.push({id: layerIdList[j], objectIds: layerMap.get(layerIdList[j])})
                          }
                          this.scheduleSimulationDataMap.put(planEndDateList[i], layerDataList)
                        }
                      }
                      if (this.scheduleSimulationDataMap && this.scheduleSimulationDataMap.size() > 0) {
                        // 同时在左侧显示模型对应的分部分项工程
                        this.dtsScheduleSimulationWbsVisible = true
                        this.$nextTick(() => {
                          this.$refs.dtsScheduleSimulationWbs.init()
                        })
                        loading.close()
                        // 这里采用setInterval循环定时执行 模型高亮、定位
                        this.scheduleSimulationTimer = setInterval(() => {
                          if (!this.isCurrentScheduleSimulationHighlight) {
                            this.isCurrentScheduleSimulationHighlight = true
                            if (this.lastScheduleSimulationKeyIndex >= 0) {
                              if (this.lastScheduleSimulationKeyIndex < this.scheduleSimulationDataMap.keys().length) {
                                let lastLayerList = this.scheduleSimulationDataMap.get(this.scheduleSimulationDataMap.keys()[this.lastScheduleSimulationKeyIndex-1])
                                let layerList = this.scheduleSimulationDataMap.get(this.scheduleSimulationDataMap.keys()[this.lastScheduleSimulationKeyIndex])
                                this.api.tileLayer.stopHighlightActors(lastLayerList).then(() => {
                                  // 显示图层中的模型
                                  this.api.tileLayer.showActors(layerList).then(() => {
                                    // 高亮
                                    this.api.tileLayer.highlightActors(layerList).then(() => {
                                      // 动态更新分部分项工程面板
                                      if (this.dtsScheduleSimulationWbsVisible) {
                                        let thisPlanEndDate = this.scheduleSimulationDataMap.keys()[this.lastScheduleSimulationKeyIndex]
                                        this.$refs.dtsScheduleSimulationWbs.showWbs(thisPlanEndDate, wbsNameMap.get(thisPlanEndDate), outputMap.get(thisPlanEndDate))
                                      }
                                      // 定位某一个图层上的一个模型
                                      this.api.tileLayer.focusActors(layerList[0], 25, 1, [-30,90,0]).then(() => {
                                        this.lastScheduleSimulationKeyIndex++
                                        this.isCurrentScheduleSimulationHighlight = false
                                      }).catch((e) => {
                                        this.lastScheduleSimulationKeyIndex++
                                        this.isCurrentScheduleSimulationHighlight = false
                                      })
                                    }).catch((e) => {
                                      this.lastScheduleSimulationKeyIndex++
                                      this.isCurrentScheduleSimulationHighlight = false
                                    })
                                  }).catch((e) => {
                                    this.lastScheduleSimulationKeyIndex++
                                    this.isCurrentScheduleSimulationHighlight = false
                                  })
                                }).catch((e) => {
                                  this.lastScheduleSimulationKeyIndex++
                                  this.isCurrentScheduleSimulationHighlight = false
                                })
                              } else {
                                // 结束进度模拟
                                this.$refs.dtsScheduleSimulation.stopSimulation()
                                loading.close()
                                this.printLog('green', "进度模拟渲染结束")
                              }
                            } else {
                              this.lastScheduleSimulationKeyIndex = 0
                              let layerList = this.scheduleSimulationDataMap.get(this.scheduleSimulationDataMap.keys()[0])
                              // 显示图层中的模型
                              this.api.tileLayer.showActors(layerList).then(() => {
                                // 高亮
                                this.api.tileLayer.highlightActors(layerList).then(() => {
                                  // 左上角显示wbs名称
                                  if (this.dtsScheduleSimulationWbsVisible) {
                                    let thisPlanEndDate = this.scheduleSimulationDataMap.keys()[0]
                                    this.$refs.dtsScheduleSimulationWbs.showWbs(thisPlanEndDate, wbsNameMap.get(thisPlanEndDate), 0, 0, 0)
                                  }
                                  // 定位某一个图层上的多个模型
                                  this.api.tileLayer.focusActors(layerList[0], 25, 1, [-30,90,0]).then(() => {
                                    this.lastScheduleSimulationKeyIndex++
                                    this.isCurrentScheduleSimulationHighlight = false
                                  }).catch((e) => {
                                    this.lastScheduleSimulationKeyIndex++
                                    this.isCurrentScheduleSimulationHighlight = false
                                  })
                                }).catch((e) => {
                                  this.lastScheduleSimulationKeyIndex++
                                  this.isCurrentScheduleSimulationHighlight = false
                                })
                              }).catch((e) => {
                                this.lastScheduleSimulationKeyIndex++
                                this.isCurrentScheduleSimulationHighlight = false
                              })
                            }
                          }
                        }, 4000)
                      } else {
                        loading.close()
                        // 结束进度模拟
                        this.$refs.dtsScheduleSimulation.stopSimulation()
                        this.printLog('green', "进度模拟渲染结束")
                      }
                    } else {
                      loading.close()
                      this.$message({
                        message: '该时间段内，未查询进度计划关联的模型！',
                        type: 'warning',
                        duration: 3000
                      })
                      // 结束进度模拟
                      this.$refs.dtsScheduleSimulation.stopSimulation()
                      this.printLog('green', "进度模拟渲染结束")
                    }
                  } else {
                    loading.close()
                    this.$message({
                      message: '查询进度模拟所挂接的模型构件记录时出现错误，res.code：' + res.code,
                      type: 'error',
                      duration: 5000
                    })
                    // 结束进度模拟
                    this.$refs.dtsScheduleSimulation.stopSimulation()
                    this.printLog('green', "进度模拟渲染结束")
                  }
                }).catch(() => {
                  loading.close()
                  this.$message({
                    message: '查询进度模拟所挂接的模型构件记录时出现错误，请稍后重试。',
                    type: 'error',
                    duration: 5000
                  })
                  // 结束进度模拟
                  this.$refs.dtsScheduleSimulation.stopSimulation()
                  this.printLog('green', "进度模拟渲染结束")
                })
              }
            }).catch(err => {
              loading.close()
              this.printLog('red', 'getTileLayerIdList-error:'+err)
            })
          }).catch(err => {
            loading.close()
            this.printLog('red', 'stopHighlightAllActors-error:'+err)
          })
      },
      // 停止进度模拟
      stopScheduleSimulation () {
        // this.printLog('green', "停止进度模拟渲染并重置相关属性")
        clearInterval(this.scheduleSimulationTimer)
        this.scheduleSimulationDataMap = new HashMap()
        this.isCurrentScheduleSimulationHighlight = false
        this.lastScheduleSimulationKeyIndex = -1
      },
      // 启动质量巡检
      async startQutyCheckMarker() {
        // 查询当前项目的所有质量检查记录
        const me = this
        let {data: res} = await this.$http.get(`/opm/qualityCheck/list/${this.currentPrjId}`)
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            for (let o of res.data) {
              let markerId = 'QutyCheckMarker_' + o.id
              if (!this.qutyCheckMap.containsKey(markerId)) {
                // 创建质量检查点标注
                // this.printLog('green',`正在创建质量巡检点：${o.id},${o.checkPos},${o.lon},${o.lat}`)
                let marker = {
                  id: markerId,
                  groupId: 'QutyCheckMarker',
                  coordinate: [o.lon,o.lat, this.currentPrjZ],
                  coordinateType: 1,// 0为投影坐标，1为WGS84类型，2为火星坐标系(GCJ02)，3为百度坐标系(BD09)，默认值：0
                  anchors: [-16, 32],// 锚点
                  range: [0.01, 10000],// 可视范围
                  imagePath: `${window.SITE_CONFIG['dtsAssetsPath']}/marker/marker_bg.png`,// 显示图片路径
                  hoverImagePath: `${window.SITE_CONFIG['dtsAssetsPath']}/marker/marker_bg_hover.png`,// 鼠标悬停时显示的图片路径
                  imageSize: [28, 28],// 图片的尺寸
                  fixedSize: true,// 图片固定尺寸，取值范围：false 自适应，近大远小，true 固定尺寸，默认值：false

                  text: o.checkPos,// 显示的文字
                  useTextAnimation: true,// 打开文字展开动画效果
                  textRange: [0.01, 10000],// 文本可视范围[近裁距离, 远裁距离]
                  textOffset: [0, 0],// 文本偏移
                  textBackgroundColor: Color.White,// 文本背景颜色
                  fontSize: 16,// 字体大小
                  fontOutlineSize: 1,// 字体轮廓线大小
                  fontColor: Color.Black,// 字体颜色
                  fontOutlineColor: Color.Green,// 字体轮廓线颜色
                  // 构造弹窗url
                  popupURL: `http://${window.location.host}/freedo/pages/qutycheck.html?id=${o.id}&apiUrl=${window.SITE_CONFIG['apiURL']}&accessToken=${Cookies.get('access_token')}`,
                  popupBackgroundColor: [1.0,1.0,1.0,0.8],// 弹窗背景颜色
                  popupSize: [800, 600],// 弹窗大小
                  popupOffset: [0, 100],// 弹窗偏移

                  showLine: false,// 标注点下方是否显示垂直牵引线
                  lineSize: [2, 10],// 垂直牵引线宽度和高度[width, height]
                  lineColor: Color.SpringGreen,// 垂直牵引线颜色
                  lineOffset: [0, 0],// 垂直牵引线偏移

                  autoHidePopupWindow: true,// 失去焦点后是否自动关闭弹出窗口
                  autoHeight: false,// 自动判断下方是否有物体
                  displayMode: 2,// 显示模式
                  priority: 0,// 避让优先级
                  occlusionCull: false// 是否参与遮挡剔除
                }
                await this.api.marker.add(marker)
                await this.api.marker.focus(marker.id, 50)
                this.qutyCheckMap.put(markerId, marker)
              }
              this.dtsQutyCheckToolbarVisible = true
              this.$nextTick(() => {
                me.$refs.dtsQutyCheckToolbar.init()
              })
              // this.printLog('green',`已创建${this.qutyCheckMap.size()}个质量巡检点`)
            }
          } else {
            this.$message({
              message: '该项目无质量检查记录',
              type: 'success',
              duration: 2000
            })
          }
        } else {
          this.$message({
            message: '查询质量检查记录时出现错误，res.code：' + res.code,
            type: 'error',
            duration: 5000
          })
        }
      },
      // 关闭质量巡检
      stopQutyCheckMarker () {
        this.api.marker.deleteByGroupId('QutyCheckMarker')
        this.qutyCheckMap.clear()
        this.dtsQutyCheckToolbarVisible = false
      },
      // 启动安全巡检
      async startSafetyCheckMarker () {
        const me = this
        let {data: res} = await this.$http.get(`/opm/safetyCheck/list/${this.currentPrjId}`)
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            for (let o of res.data) {
              let markerId = 'SafetyCheckMarker_' + o.id
              if (!this.safetyCheckMap.containsKey(markerId)) {
                // 创建质量检查点标注
                // this.printLog('green',`正在创建安全巡检点：${o.id},${o.checkPos},${o.lon},${o.lat}`)
                let marker = {
                  id: markerId,
                  groupId: 'SafetyCheckMarker',
                  coordinate: [o.lon, o.lat, this.currentPrjZ],
                  coordinateType: 1,// 0为投影坐标，1为WGS84类型，2为火星坐标系(GCJ02)，3为百度坐标系(BD09)，默认值：0
                  anchors: [-16, 32],// 锚点
                  range: [0.01, 10000],// 可视范围
                  imagePath: `${window.SITE_CONFIG['dtsAssetsPath']}/marker/marker_bg.png`,// 显示图片路径
                  hoverImagePath: `${window.SITE_CONFIG['dtsAssetsPath']}/marker/marker_bg_hover.png`,// 鼠标悬停时显示的图片路径
                  imageSize: [28, 28],// 图片的尺寸
                  fixedSize: false,// 图片固定尺寸，取值范围：false 自适应，近大远小，true 固定尺寸，默认值：false

                  text: o.checkPos,// 显示的文字
                  useTextAnimation: true,// 打开文字展开动画效果
                  textRange: [0.01, 10000],// 文本可视范围[近裁距离, 远裁距离]
                  textOffset: [0, 0],// 文本偏移
                  textBackgroundColor: Color.White,// 文本背景颜色
                  fontSize: 16,// 字体大小
                  fontOutlineSize: 1,// 字体轮廓线大小
                  fontColor: Color.Black,// 字体颜色
                  fontOutlineColor: Color.Green,// 字体轮廓线颜色
                  //构造弹窗url
                  popupURL: `http://${window.location.host}/freedo/pages/safetycheck.html?id=${o.id}&apiUrl=${window.SITE_CONFIG['apiURL']}&accessToken=${Cookies.get('access_token')}`,
                  popupBackgroundColor: [1.0,1.0,1.0,0.8],// 弹窗背景颜色
                  popupSize: [800, 600],// 弹窗大小
                  popupOffset: [0, 100],// 弹窗偏移

                  showLine: false,// 标注点下方是否显示垂直牵引线
                  lineSize: [2, 10],// 垂直牵引线宽度和高度[width, height]
                  lineColor: Color.SpringGreen,// 垂直牵引线颜色
                  lineOffset: [0, 0],// 垂直牵引线偏移

                  autoHidePopupWindow: true,// 失去焦点后是否自动关闭弹出窗口
                  autoHeight: false,// 自动判断下方是否有物体
                  displayMode: 2,// 显示模式
                  priority: 0,// 避让优先级
                  occlusionCull: false// 是否参与遮挡剔除
                }
                await this.api.marker.add(marker)
                await this.api.marker.focus(marker.id, 50)
                this.safetyCheckMap.put(markerId, marker)
              }
              this.dtsSafetyCheckToolbarVisible = true
              this.$nextTick(() => {
                me.$refs.dtsSafetyCheckToolbar.init()
              })
              // this.printLog('green',`已创建${this.safetyCheckMap.size()}个安全巡检点`)
            }
          } else {
            this.$message({
              message: '该项目无安全检查记录',
              type: 'success',
              duration: 2000
            })
          }
        } else {
          this.$message({
            message: '查询安全检查记录时出现错误，res.code：' + res.code,
            type: 'error',
            duration: 5000
          })
        }
      },
      // 关闭安全巡检
      stopSafetyCheckMarker () {
        this.api.marker.deleteByGroupId('SafetyCheckMarker')
        this.safetyCheckMap.clear()
        this.dtsSafetyCheckToolbarVisible = false
      },
      // 右侧工具栏面板点击事件
      async toolbarSelectHandle (key) {
        // this.printLog('purple', `点击了工具栏中的：${key}`)
        if (key === '项目切换') {
          this.openProjectChangeDialog()
        } else if (key === '图层管理') {
          this.openLayerManagerDialog()
        } else if (key === '形象进度') {
          this.openVisualScheduleQueryDialog()
        } else if (key === '进度模拟') {
          this.openScheduleSimulationDialog()
        } else if (key === '质量巡检') {
          this.startQutyCheckMarker()
        } else if (key === '安全巡检') {
          this.startSafetyCheckMarker()
        } else if (key === '查看属性') {
          if (this.tileLayerCurSel) {
            this.dtsWbsPropVisible = true
            this.$nextTick(() => {
              this.$refs.dtsWbsProp.init(this.currentPrjId, this.tileLayerCurSel)
            })
          } else {
            this.$message({
              message: '请点选要查看的模型',
              type: 'error',
              duration: 3000
            })
          }
        } else if (key === '统计图表') {
          // 显示首页的统计图组件
          this.openDashboard()
        } else if(key === '飞行漫游') {
          // 五种交互模式，默认值：0；【0：漫游，1：人物，2：无人机，3：中心漫游（物体观察），4：地图】
          this.api.settings.setInteractiveMode(2)
          this.$message({
            message: '按键盘左上角【ESC】键可结束飞行漫游',
            type: 'info',
            duration: 5000
          })
        }else if(key === '人物漫游') {
          // 五种交互模式，默认值：0；【0：漫游，1：人物，2：无人机，3：中心漫游（物体观察），4：地图】
          this.api.settings.setInteractiveMode(1)
          this.$message({
            message: '按键盘左上角【ESC】键可结束人物漫游',
            type: 'info',
            duration: 5000
          })
        }
        else if(key === '车载漫游') {
          this.dtsVehicleRoamingVisible = true
          this.$nextTick(() => {
            this.$refs.dtsVehicleRoaming.init(this.currentPrjId)
          })
        } else if(key === '直线测量') {
          this.showMeasurementTips()
          this.api.tools.setMeasurement(MeasurementMode.Linear, this.measurementOptions)
          this.api.tools.startMeasurement()
        } else if(key === '水平测量') {
          this.showMeasurementTips()
          this.api.tools.setMeasurement(MeasurementMode.Horizontal, this.measurementOptions)
          this.api.tools.startMeasurement()
        } else if(key === '垂直测量') {
          this.showMeasurementTips()
          this.api.tools.setMeasurement(MeasurementMode.Vertical, this.measurementOptions)
          this.api.tools.startMeasurement()
        } else if(key === '投影面积') {
          this.showMeasurementTips()
          this.api.tools.setMeasurement(MeasurementMode.MultiPoint, this.measurementOptions)
          this.api.tools.startMeasurement()
        } else if(key === '地表面积') {
          this.showMeasurementTips()
          this.api.tools.setMeasurement(MeasurementMode.TerrainArea, this.measurementOptions)
          this.api.tools.startMeasurement()
        } else if(key === '体剖切') {
          // 暂无
        } else if(key === '面剖切') {
          // let cameraPos = await this.api.camera.get()
          if (this.tileLayerCurSel) {
            this.showMeasurementTips()
            this.api.tools.startPlaneClip(this.tileLayerCurSel.location, [0, 0, 0], true, true)
          } else {
            this.$message({
              message: '请点选要剖切的模型',
              type: 'error',
              duration: 3000
            })
          }
        } else if(key === '多边形剖切') {
          // 暂无
        } else if(key === '挖洞') {
          // 暂无
        } else if(key === '地形透明度') {
          this.dtsTerrainVisible=true
          this.$nextTick(() => {
            this.$refs.dtsTerrain.init()
          })
        } else if(key === '地形开关') {
          let tileLayerIdList= await this.getTileLayerIdList(false,true,false)
          if(tileLayerIdList&&tileLayerIdList.length>0){
            if(this.terrainDisplaySwitch){
              // 隐藏模型
             await this.api.tileLayer.hide(tileLayerIdList)
            }else{
              // 显示模型
              await this.api.tileLayer.show(tileLayerIdList)
            }
            this.terrainDisplaySwitch=!this.terrainDisplaySwitch
          }
        } else if(key === '倾斜摄影开关') {
          let tileLayerIdList= await this.getTileLayerIdList(false,false,true)
          if(tileLayerIdList&&tileLayerIdList.length>0){
            if(this.osgbDisplaySwitch){
              // 隐藏模型
              await this.api.tileLayer.hide(tileLayerIdList)
            }else{
              // 显示模型
              await this.api.tileLayer.show(tileLayerIdList)
            }
            this.osgbDisplaySwitch=!this.osgbDisplaySwitch
          }
        } else if(key === '高程开关') {
          // TODO 未找到如何关闭高程 (暂不开发)
        } else if(key === '日照分析') {
          // let options = {
          //   year: 2023,//年，取值范围：[1970~至今年份]，默认值：当前日期
          //   month: 4,//月，取值范围：[1~12]，默认值：当前日期
          //   day: 7,//日，取值范围：[1~31]，默认值：当前日期
          //   startTime: "08:00",//开始时间，默认值：08:00
          //   endTime: "16:00",//结束时间，默认值：16:00
          //   spacing: -1,//间距，取值范围：[任意负数~任意正数]，默认值：-1米
          //   groundElevation: 0,//底面高度，取值范围：[任意负数~任意正数]，默认值：0米
          //   height: 5000,//高度，取值范围：[0~任意正数]，默认值：5000米
          //   sphereRadius: 10//日照效果球半径，取值范围：[0~任意正数]
          // }
          // this.api.tools.startSunshineAnalysis(options)
        } else if(key === '挖填方分析') {

        } else if(key === '可视域分析') {

        } else if(key === '天气特效') {
          this.dtsWeatherVisible = true
          this.$nextTick(() => {
            this.$refs.dtsWeather.init()
          })
        } else if(key==='视频监控') {
          this.startCctvMarker()
        } else if(key==='开启分屏') {
          if(this.multiViewPortSwitch){
            // 关闭分屏（退出多视口）
            await this.api.misc.exitMultiViewportMode();
            // 使用exitMultiViewportMode退出多视口模式时，页面会同时显示dts默认的工具栏、图层树列表
            // 这里需要设置dts默认的界面UI隐藏
            this.api.settings.setMainUIVisibility(false)
          }else{
            this.$message({
              message: '正在开启分屏浏览...',
              type: 'info',
              duration: 1500
            })
            let tileLayerIdList= await this.getTileLayerIdList(false,true,true)
            if(tileLayerIdList && tileLayerIdList.length >  0){
              for(let i = 0;i < tileLayerIdList.length; i++){
                // TileLayer提供setViewportVisible接口方法，可以设置对象各视口的可见性。
               await this.api.tileLayer.setViewportVisible(tileLayerIdList[i],1)
              }
            }
            //视口布局类型，取值范围：[1~7]
            let viewportMode = 2;
            //可选参数，激活后视口边框线的颜色
            let lineColor = "#DEA309";
            //可选参数，激活后视口边框线的宽度，单位：像素px
            let lineSize = 2;
            // 开启分屏
            await this.api.misc.enterMultiViewportMode(viewportMode, lineColor, lineSize);
            // 多视口模式下设置相机同步
            this.api.misc.setMultiviewportInteractSync(true);
          }
          this.multiViewPortSwitch =! this.multiViewPortSwitch
        }
      },
      // 如果构件属性面板已经打开了，那么用户在点选各个模型的时候需要同时刷新该面板
      refreshWbsProp() {
        if (this.dtsWbsPropVisible) {// 判断构件属性面板是否已打开
          if (this.tileLayerCurSel && this.tileLayerCurSel.layerId && this.tileLayerCurSel.objectId && this.currentPrjId) {
            this.$refs.dtsWbsProp.init(this.currentPrjId, this.tileLayerCurSel)
          }
        }
      },
      // 改变云层效果
      cloudChange(e) {
        this.api.weather.setCloudDensity(e) // 云层密度（0-1）
        this.api.weather.setCloudThickness(e * 10) // 云层厚度（0-20）
      },
      // 改变下雨效果
      rainChange(e) {
        let factor = 0.2 // 为了防止失真，强度整体降低为20%
        this.api.weather.setRainParam(e * factor, e * 10 * factor, e * 5 * factor)
      },
      // 改变下雪效果
      snowChange(e) {
        let factor = 0.1 // 为了防止失真，强度整体降低为10%
        this.api.weather.setSnowParam(e * factor, e * 10 * factor, e * 15 * factor)
      },
      // 重置天气效果
      resetWeather() {
        this.api.weather.setCloudThickness(0)
        this.api.weather.setRainParam(0, 0, 0)
        this.api.weather.setSnowParam(0, 0, 0)
      },
      // 关闭天气特效
      closeWeatherHandle() {
        this.resetWeather()
        this.dtsWeatherVisible = false
      },
      // 改变地形透明度
      terrainChange(e){
        this.api.settings.setTerrainAlpha(e)
      },
      closeWbsPropHandle () {
        this.dtsWbsPropVisible = false
      },
      showMeasurementTips() {
        this.$message({
          message: '按键盘左上角【ESC】键可结束当前测量动作',
          type: 'info',
          duration: 5000
        })
      },
      // 启动创建视频监控点
      async startCctvMarker () {
        // 查询当前项目的所有视频监控点记录列表
        const me = this
        let {data: res} = await this.$http.get(`/opm/cctv/list/${this.currentPrjId}`)
        if (res.code === 0) {
          if (res.data && res.data.length > 0) {
            this.cctvToolbarVisible = true
            this.$nextTick(() => {
              me.$refs.cctvToolbar.init()
            })
            
            for (let o of res.data) {
              let markerId = o.id
              if (!this.cctvMap.containsKey(markerId)) {
                // 创建视频监控点标注
                // this.printLog('green',`正在创建视频监控点：${o.id},${o.pointName},${o.x},${o.y},${o.z}`)
                let marker = {
                  id: markerId,
                  groupId: 'CctvMarker',
                  coordinate: [o.x, o.y, o.z || this.currentPrjZ],
                  coordinateType: 1,// 0为投影坐标，1为WGS84类型，2为火星坐标系(GCJ02)，3为百度坐标系(BD09)，默认值：0
                  anchors: [-16,32],// 锚点
                  range: [0.01,10000],// 可视范围
                  imagePath: `${window.SITE_CONFIG['dtsAssetsPath']}/marker/webcam.png`,// 显示图片路径
                  hoverImagePath: `${window.SITE_CONFIG['dtsAssetsPath']}/marker/webcam.png`,// 鼠标悬停时显示的图片路径
                  imageSize: [28,28],// 图片的尺寸
                  fixedSize: true,// 图片固定尺寸，取值范围：false 自适应，近大远小，true 固定尺寸，默认值：false

                  text: o.pointName,// 显示的文字
                  useTextAnimation: true,// 打开文字展开动画效果
                  textRange: [0.01,10000],// 文本可视范围[近裁距离, 远裁距离]
                  textOffset: [0,0],// 文本偏移
                  textBackgroundColor: Color.White,// 文本背景颜色
                  fontSize: 16,// 字体大小
                  fontOutlineSize: 1,// 字体轮廓线大小
                  fontColor: Color.Black,// 字体颜色
                  fontOutlineColor: Color.Green,// 字体轮廓线颜色
                  // 构造弹窗url地址
                  // 改为vxe-modal弹窗打开
                  // popupURL: o.liveUrl || '',
                  // 保存视频直播地址，用于在点击事件中通过组件打开视频监控画面。
                  userData: o.liveUrl || '',
                  popupBackgroundColor: [1.0,1.0,1.0,0.8],// 弹窗背景颜色
                  popupSize: [600,400],// 弹窗大小
                  popupOffset: [0,100],// 弹窗偏移

                  showLine: false,// 标注点下方是否显示垂直牵引线
                  lineSize: [2,10],// 垂直牵引线宽度和高度[width, height]
                  lineColor: Color.SpringGreen,// 垂直牵引线颜色
                  lineOffset: [0,0],// 垂直牵引线偏移

                  autoHidePopupWindow: true,// 失去焦点后是否自动关闭弹出窗口
                  autoHeight: false,// 自动判断下方是否有物体
                  displayMode: 2,// 显示模式
                  priority: 0,// 避让优先级
                  occlusionCull: false// 是否参与遮挡剔除
                }
                await this.api.marker.add(marker)
                await this.api.marker.focus(marker.id, 100)
                this.cctvMap.put(markerId, marker)
              }
              // this.printLog('green',`已创建${this.cctvMap.size()}个视频监控点`)
            }
          } else {
            this.$message({
              message: '该项目无视频监控点记录',
              type: 'success',
              duration: 2000
            })
          }
        } else {
          this.$message({
            message: '查询视频监控点记录时出现错误，res.code：' + res.code,
            type: 'error',
            duration: 5000
          })
        }
      },
      // 关闭视频监控点
      stopCctvMarker () {
        this.api.marker.deleteByGroupId('CctvMarker')
        this.cctvMap.clear()
        this.cctvToolbarVisible = false
      },
      // 打开视频监控画面组件
      openCctvDialog (liveUrl) {
        if (!this.cctv1Visible) {
          this.cctv1Visible = true
          this.$nextTick(() => {
            this.$refs.cctv1.init(liveUrl, 10)
          })
        } else if (!this.cctv2Visible) {
          this.cctv2Visible = true
          this.$nextTick(() => {
            this.$refs.cctv2.init(liveUrl, 420)
          })
        } else if (!this.cctv3Visible) {
          this.cctv3Visible = true
          this.$nextTick(() => {
            this.$refs.cctv3.init(liveUrl, 830)
          })
        } else {
          this.$message({
            message: '最多支持同时打开3个视频监控窗口。',
            type: 'error',
            duration: 5000
          })
        }
      },
      // 关闭打开的视频监控画面
      closeCctvDialog(index) {
        if (index == 1) {
          this.cctv1Visible = false
        } else if (index == 2) {
          this.cctv2Visible = false
        } else if (index == 3) {
          this.cctv3Visible = false
        }
      },
      // 打开统计图表组件
      openDashboard() {
        // 首先关闭所有已打开的窗口
        this.closeAllWindows()
        // 显示首页的统计图组件
        this.dtsDashboardVisible = true
        this.$nextTick(() => {
          this.$refs.dtsDashboard.init(this.currentPrjId, this.currentPrjName)
        })
      },
      // 关闭统计图表组件
      closeDashboard() {
        this.dtsDashboardVisible = false
      },
      // 开始车载漫游
      async startVehicleRoaming (coordinates) {
        await this.stopVehicleRoaming()

        let pathArr = []
        if (coordinates && coordinates.length > 0) {
          pathArr = coordinates
        } else {
          // TODO 为了方便演示，在没有查询到车载路线的时候暂时使用以下伪代码数组
          pathArr = [
            [493321.09375, 2491909.25, -0.886854887008667],
            [493316.90625, 2491909.25, -0.8868560791015625],
            [493309.59375, 2491909.75, -0.886854887008667],
            [493298.875, 2491909.75, -0.8868560791015625],
            [493282.53125, 2491910, -0.8868554830551147],
            [493261.46875, 2491910.5, -0.8868542313575745],
            [493250.46875, 2491910.5, -0.8868566751480103],
            [493235.375, 2491910.75, -0.8868542313575745],
            [493224.875, 2491911, -0.8868554830551147],
            [493206.9375, 2491911.25, -0.8868554830551147],
            [493203.46875, 2491911.5, -0.8868560791015625],
            [493191.09375, 2491911.5, -0.8868542313575745],
            [493168.75, 2491912.25, -0.8868560791015625],
            [493152.90625, 2491912.75, -0.8868554830551147],
            [493145, 2491912.75, -0.8868554830551147],
            [493132.65625, 2491913, -0.8868563771247864],
            [493117.53125, 2491913.25, -0.8868563771247864],
            [493103.6875, 2491913.5, -0.8868579268455505],
            [493092.71875, 2491913.75, -0.8868603706359863],
            [493076.5, 2491913.75, -0.886853039264679],
            [493067.875, 2491914, -0.8868554830551147],
            [493061.09375, 2491914, -0.8868554830551147],
            [493050.6875, 2491914.25, -0.8868554830551147],
            [493039.03125, 2491914.25, -0.8868554830551147],
            [493026.375, 2491914.5, -0.8868554830551147],
            [493017.4375, 2491914.75, -0.8868554830551147],
            [493006.25, 2491915, -0.8868554830551147],
            [492991.125, 2491915.5, -0.8868505954742432],
            [492979.15625, 2491915.75, -0.8868603706359863],
          ]
        }

        if (this.showVehicleRoamingPolyline) {
          let polylineId = 'polyline_' + new Date().getTime()
          this.vehicleRoamingPolylineIds.push(polylineId)
          await this.api.polyline.add({
            id: polylineId,
            coordinates: pathArr,//构成折线的坐标点数组
            coordinateType: 0,// 坐标系类型 0投影 1经纬度
            range: [1, 10000],//可视范围：[近裁距离, 远裁距离]，取值范围: [任意负值, 任意正值]
            color: Color.Green,//折线颜色
            thickness: 1,
            intensity: 1,//亮度
            flowRate: 1,//流速
            shape: 1,//类型 0：直线， 1：曲线
            depthTest: true,//是否做深度检测 开启后会被地形高度遮挡
            style: PolylineStyle.Arrow1,//折线样式 参考样式枚举：PolylineStyle
            tiling: 20 //材质贴图平铺比例
          })
        }
        
        let distance = 6.8 // 如果显示载具的话，以车外视角查看
        if (!this.showVehicleAsset) {// 不显示载具的话，就将视角直接定在车头之前
          distance = -1
        }

        // 添加车辆模型
        await this.api.vehicle.add({
          "id": "vc1",
          "coordinateType": 0, // 0为投影坐标，1为WGS84类型，2为火星坐标系(GCJ02)，3为百度坐标系(BD09)
          "assetPath": "/JC_CustomAssets/VehicleLibrary/Exhibition/轿车_07",//资源库车辆路径
          "coordinate": pathArr[0], // 车辆的初始位置和方向数组
          "rotation": [0, 180, 0],
          "autoHeight": true
        })

        await this.api.vehicle.focus('vc1')

        // 运动轨迹点
        let wayPoints = []
        for (let i = 0; i < pathArr.length; i++) {
          wayPoints.push({ 
            "coordinate": pathArr[i],
            "gear": 3,
            "timeStamp": i * (this.vehicleRoamingSpeed / 1000)
          })
        }

        // 镜头跟随
        await this.api.vehicle.focus('vc1', true, distance, 0, [2, 6, 0], [10, 0, 0])

        await this.api.vehicle.setWayPoint({
          "id": "vc1",
          "wayPoints": wayPoints
        })

        await this.api.vehicle.start([{
          id: 'vc1',
          timeStamp: 0,// 设置载具v1从wayPoints的第0秒开始运动
        }])
      },
      // 停止车载漫游
      async stopVehicleRoaming () {
        await this.api.vehicle.stop("vc1")
        await this.api.vehicle.clear()
        if (this.vehicleRoamingPolylineIds && this.vehicleRoamingPolylineIds.length > 0) {
          await this.api.polyline.delete(this.vehicleRoamingPolylineIds)
        }
        this.vehicleRoamingPolylineIds = []
      },
      // 关闭车载漫游面板
      closeVehicleRoaming () {
        this.dtsVehicleRoamingVisible = false
        this.stopVehicleRoaming()
        this.clearVisualScheduleRendering()
      },
      // 控制是否显示车辆模型
      changeVehicleAssetVisibleHandle (visible) {
        this.showVehicleAsset = visible
      },
      // 控制是否显示车载漫游时的箭头导引线
      changeVehicleRoamingPolylineVisibleHandle (visible) {
        this.showVehicleRoamingPolyline = visible
      },
      // 车载漫游时开启/关闭形象进度
      changeVisualScheduleVisibleHandle(visible) {
        if (visible) {
          this.startVisualScheduleRendering(false)
        } else {
          this.clearVisualScheduleRendering()
        }
      },
      // 修改车载漫游的车速
      changeVehicleRoamingSpeedHandle (speed) {
        this.vehicleRoamingSpeed = speed
      },
      // 暂停/恢复车载漫游
      pauseOrResumeHandle() {
        if (this.pauseRoaming) {
          this.pauseRoaming = false
          this.api.vehicle.resume("vc1")
        } else {
          this.pauseRoaming = true
          this.api.vehicle.pause("vc1")
        }
      },
      // 关闭所有打开的窗口，包括子组件窗口、Marker等
      closeAllWindows() {
        // 关闭[项目切换窗口]
        this.dtsProjectSelectVisible = false

        // 关闭[图层管理]
        this.closeLayerManagerHandle()

        // 关闭[形象进度]
        this.closeVisualScheduleQueryDialog()

        // 关闭[进度模拟]
        this.closeScheduleSimulationDialog()

        // 关闭[质量巡检]
        this.stopQutyCheckMarker()

        // 关闭[安全巡检]
        this.stopSafetyCheckMarker()

        // 关闭[构件属性]
        this.closeWbsPropHandle()

        // 关闭[车载漫游]
        this.closeVehicleRoaming()

        // 关闭[天气特效]
        this.closeWeatherHandle()

        // 关闭[视频监控]
        this.stopCctvMarker()

        // 关闭[统计图表看板]
        this.closeDashboard()

        // 设置交互模式为漫游 默认值：0；【0：漫游，1：人物，2：无人机，3：中心漫游（物体观察），4：地图】
        this.api.settings.setInteractiveMode(0)
        // 关闭质量巡检已经打开的弹窗
        let qutyCheckKeys = this.qutyCheckMap.keys()
        if (qutyCheckKeys && qutyCheckKeys.length > 0) {
          this.api.marker.hidePopupWindow(qutyCheckKeys)
        }
        // 关闭安全巡检已经打开的弹窗
        let safetyCheckKeys = this.safetyCheckMap.keys()
        if (safetyCheckKeys && safetyCheckKeys.length > 0) {
          this.api.marker.hidePopupWindow(safetyCheckKeys)
        }
        // 关闭视频监控已经打开的弹窗
        let cctvKeys = this.cctvMap.keys()
        if (cctvKeys && cctvKeys.length > 0) {
          this.api.marker.hidePopupWindow(cctvKeys)
        }
        // 取消测量模式
        this.api.tools.stopMeasurement()
        // 停止面剖切
        this.api.tools.stopPlaneClip()
      },
    }
  }
</script>
<style lang="scss" scoped>
  body {
    background-color: honeydew;
    font-family: Verdana;
    font-size: 14px;
    overflow: hidden;
  }
  .container {
    width: 100%;
    height: 100%;
  }

  .player {
    position: relative;
    background-color: black;
    // margin-top: 5px;
    width: 100%;
    height: calc(100vh - 0px);
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
</style>