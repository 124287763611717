<template>
  <vxe-modal width="350" :height="height" min-height="400" :position="position" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" v-on="$listeners">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>{{prjName}}</div>
    </span>
    <div class="dts-dialog-body">
      <!-- 产值统计 -->
      <div id="outputChart" :style="{width: '100%', height: '300px'}"></div>
      <!-- 质量检查 -->
      <div id="qutyCheckChart" :style="{width: '100%', height: '200px'}"/>
      <!-- 安全检查 -->
      <div id="safetyCheckChart" :style="{width: '100%', height: '200px'}"/>
    </div>
  </vxe-modal>
</template>

<script>
// 当前组件是三维数字孪生平台的首页统计图表
export default {
  data () {
    return {
      prjId: '',
      prjName: '',
      height: `calc(90%)`,
      position: {
        top: 40,
        left: 10
      },
      visible: false,
      xAxisData: [],// 曲线图x轴显示的日期
      chartLoadingText:{// 图表loading效果
        text: '统计中，请稍候...',
        color: '#c23531',
        textColor: '#000',
        maskColor: 'rgba(3,3,8,0.1)',
        zlevel: 0,
      },
      year: new Date().getFullYear(),
      outputChart: {
        instance: null
      },
      qutyCheckChart: {
        instance: null
      },
      safetyCheckChart: {
        instance: null
      },
    }
  },
  methods: {
    init (prjId, prjName) {
      this.prjId = prjId
      this.prjName = prjName
      this.visible = true
      this.$nextTick(() => {
        this.showOutputChart() // 产值统计
        this.showQutyCheckChart() // 质量问题
        this.showSafetyCheckChart() // 安全问题
      })
    },
    // 产值统计
    showOutputChart() {
      if (!this.outputChart.instance) {
        this.outputChart.instance = this.$echarts.init(document.getElementById('outputChart'))
      }
      let chartData = {
        // 标段名/项目名
        names: [],
        // 合同额
        amounts: [],
        // 变更后金额
        changedAmounts: [],
        // 累计完成
        totalFinished: [],
        // 年度计划产值
        annualPlan: [],
        // 本年完成产值
        annualFinished: []
      }
      this.outputChart.instance.showLoading(this.chartLoadingText)
      this.$http.get(`/opm/home/outputChart?prjId=${this.prjId}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message({
            message: res.msg,
            type: 'error',
            duration: 3000
          })
        }
        chartData = res.data
        this.setOutputChartOption(chartData)
        this.outputChart.instance.hideLoading()
      }).catch(() => {
        // do nothing
      })
    },
    setOutputChartOption (chartData) {
      var linear1 = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#02bcff' // 0% 处的颜色
        }, {
          offset: 1, color: '#5555ff' // 100% 处的颜色
        }],
        global: false // 缺省为 false
      }
      var linear2 = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFCCFF' // 0% 处的颜色
        }, {
          offset: 1, color: '#FF00FF' // 100% 处的颜色
        }],
      }
      var linear3 = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#00FF00' // 0% 处的颜色
        }, {
          offset: 1, color: '#00BB00' // 100% 处的颜色
        }],
      }
      var linear4 = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FFFFBB' // 0% 处的颜色
        }, {
          offset: 1, color: '#FFFF00' // 100% 处的颜色
        }],
      }
      var linear5 = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#99FFFF' // 0% 处的颜色
        }, {
          offset: 1, color: '#00FFFF' // 100% 处的颜色
        }],
      }
      var option = {
        title: {
          // text: '[产值]',
          textStyle: {
            color: '#ffffff',
            fontSize: '12px'
          },
        },
        grid: {// 控制上下左右边距
          x: 5,
          y: 60,
          x2: 5,
          y2: 10,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          // 处理千分位
          formatter (params) {
            let relVal = params[0].name
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}${params[i].seriesName} : ${params[i].value.toLocaleString()} 万`
            }
            return relVal
          }
        },
        legend: {
          data: ['合同金额', '变更后合同额', '累计完成', '年度计划产值', '本年完成产值'],
          textStyle: {
            color: '#ffffff',
          }
        },
        xAxis: {
          data: chartData.names,
          axisLabel: {
            textStyle: {
              color: "#ffffff",
            },
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: false,
            formatter: '{value} 万',
            textStyle: {
              color: "#ffffff",
            },
          }
        },
        series: [
          {
            name: '合同金额',
            type: 'bar',
            barMaxWidth: '30',
            data: chartData.amounts,
            itemStyle: {
              normal: {
                // color: '#00A3E0',
                color: linear1,
						    borderRadius: [20, 20, 0, 0],
                label: {
                  show: true, //开启显示数值
                  position: 'top', //数值在上方显示
                  textStyle: {  //数值样式
                    color: '#5555ff', //字体颜色
                    fontSize: 12  //字体大小
                  },
                  formatter (a) {
                    return a.value.toLocaleString()
                  }
                }
              }
            }
          },
          {
            name: '变更后合同额',
            type: 'bar',
            barMaxWidth: '30',
            data: chartData.changedAmounts,
            itemStyle: {
              normal: {
                // color: '#FF00FF',
                color: linear2,
						    borderRadius: [20, 20, 0, 0],
                label: {
                  show: true, //开启显示数值
                  position: 'top', //数值在上方显示
                  textStyle: {  //数值样式
                    color: '#FF00FF', //字体颜色
                    fontSize: 12  //字体大小
                  }
                }
              }
            }
          },
          {
            name: '累计完成',
            type: 'bar',
            barMaxWidth: '30',
            data: chartData.totalFinished,
            itemStyle: {
              normal: {
                // color: '#00CCFF',
                color: linear3,
						    borderRadius: [20, 20, 0, 0],
                label: {
                  show: true, //开启显示数值
                  position: 'top', //数值在上方显示
                  textStyle: {  //数值样式
                    color: '#00BB00',   //字体颜色
                    fontSize: 12  //字体大小
                  }
                }
              }
            }
          },
          {
            name: '年度计划产值',
            type: 'bar',
            barMaxWidth: '30',
            data: chartData.annualPlan,
            itemStyle: {
              normal: {
                // color: '#FF9999',
                color: linear4,
						    borderRadius: [20, 20, 0, 0],
                label: {
                  show: true, //开启显示数值
                  position: 'top', //数值在上方显示
                  textStyle: {  //数值样式
                    color: '#FFFF00', //字体颜色
                    fontSize: 12  //字体大小
                  }
                }
              }
            }
          },
          {
            name: '本年完成产值',
            type: 'bar',
            barMaxWidth: '30',
            data: chartData.annualFinished,
            itemStyle: {
              normal: {
                // color: '#9933FF',
                color: linear5,
						    borderRadius: [20, 20, 0, 0],
                label: {
                  show: true, //开启显示数值
                  position: 'top', //数值在上方显示
                  textStyle: {  //数值样式
                    color: '#00FFFF', //字体颜色
                    fontSize: 12  //字体大小
                  }
                }
              }
            }
          }
        ]
      }
      this.outputChart.instance.clear()
      this.outputChart.instance.setOption(option)
    },
    // 质量问题
    showQutyCheckChart() {
      if (!this.qutyCheckChart.instance) {
        this.qutyCheckChart.instance = this.$echarts.init(document.getElementById('qutyCheckChart'))
      }
      let chartData = {
        months: [],
        vals: [],
      }
      this.qutyCheckChart.instance.showLoading(this.chartLoadingText)
      this.$http.get(`/opm/home/qutyCheckChart/${this.prjId}/${this.year}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message({
            message: res.msg,
            type: 'error',
            duration: 3000
          })
        }
        chartData = res.data
        this.qutyCheckChartOption(chartData)
        this.qutyCheckChart.instance.hideLoading()
      })
    },
    // 质量检查统计分析-参数设置
    qutyCheckChartOption (chartData) {
      var linear = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#FF99FF' // 0% 处的颜色
        }, {
          offset: 1, color: '#FF33FF' // 100% 处的颜色
        }],
        global: false // 缺省为 false
      }
      
      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          formatter (params) {
            let relVal = params[0].name
            if (relVal.startsWith('0')) {
              relVal = relVal.substring(1)
            }
            relVal += '月'
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}${params[i].seriesName} : ${params[i].value}`
            }
            return relVal
          }
        },
        grid: {// 控制上下左右边距
          x: 5,
          y: 40,
          x2: 5,
          y2: 10,
          containLabel: true
        },
        title: {
          text: '[质量问题]',
          textStyle: {
            color: '#ffffff',
            fontSize: '12px'
          },
        },
        xAxis: {
          data: chartData.months,
          axisLabel: {
            textStyle: {
              color: "#ffffff",
            },
            formatter: function(value) {
              if (value.startsWith('0')) {
                value = value.substring(1)
              }
              return value + '月'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: "#ffffff",
            },
          }
        },
        series: [
          {
            name: '问题数量',
            type: 'bar',
            barMaxWidth: '30',
            data: chartData.vals,
            itemStyle: {
              normal: {
                // color: '#9933FF',
                color: linear,
						    borderRadius: [10, 10, 0, 0],
                label: {
                  show: true, //开启显示数值
                  position: 'top', //数值在上方显示
                  textStyle: {  //数值样式
                    color: '#FF33FF',   //字体颜色
                    fontSize: 12  //字体大小
                  }
                }
              }
            }
          },
          // {
          //   name: "各标段占比",
          //   type: "pie",
          //   // radius:80,
          //   radius: [30, 25],
          //   left: 180,
          //   top: -40,
          //   data: chartData.pieChartData,
          //   itemStyle: {
          //     normal: {
          //       color: linear,
          //     }
          //   }
          // }
        ]
      }
      this.qutyCheckChart.instance.clear()
      this.qutyCheckChart.instance.setOption(option)
    },
    // 安全检查
    showSafetyCheckChart () {
      if (!this.safetyCheckChart.instance) {
        this.safetyCheckChart.instance = this.$echarts.init(document.getElementById('safetyCheckChart'))
      }
      let chartData = {
        months: [],
        vals: [],
      }
      this.safetyCheckChart.instance.showLoading(this.chartLoadingText)
      this.$http.get(`/opm/home/safetyCheckChart/${this.prjId}/${this.year}`).then(({data: res}) => {
        if (res.code !== 0) {
          return this.$message({
            message: res.msg,
            type: 'error',
            duration: 3000,
          })
        }
        chartData = res.data
        this.safetyCheckChartOption(chartData)
        this.safetyCheckChart.instance.hideLoading()
      }).catch(() => {
        // do nothing
      })
    },
    // 安全检查统计分析-参数设置
    safetyCheckChartOption (chartData) {
      var linear = {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2:1,
        colorStops: [{
          offset: 0, color: '#00FF00' // 0% 处的颜色
        }, {
          offset: 1, color: '#00AA00' // 100% 处的颜色
        }],
        global: false // 缺省为 false
      }

      var option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
            label: {
              show: true
            }
          },
          formatter (params) {
            let relVal = params[0].name
            if (relVal.startsWith('0')) {
              relVal = relVal.substring(1)
            }
            relVal += '月'
            for (let i = 0,l = params.length; i < l; i++) {
              relVal += `<br/>${params[i].marker}${params[i].seriesName} : ${params[i].value}`
            }
            return relVal
          }
        },
        grid: {// 控制上下左右边距
          x: 5,
          y: 40,
          x2: 5,
          y2: 10,
          containLabel: true
        },
        title: {
          text: '[安全问题]',
          textStyle: {
            color: '#ffffff',
            fontSize: '12px'
          },
        },
        xAxis: {
          data: chartData.months,
          axisLabel: {
            textStyle: {
              color: "#ffffff",
            },
            formatter: function(value) {
              if (value.startsWith('0')) {
                value = value.substring(1)
              }
              return value + '月'
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value}',
            textStyle: {
              color: "#ffffff",
            },
          }
        },
        series: [
          {
            name: '问题数量',
            type: 'bar',
            barMaxWidth: '30',
            data: chartData.vals,
            itemStyle: {
              normal: {
                // color: '#9933FF',
                color: linear,
						    borderRadius: [10, 10, 0, 0],
                label: {
                  show: true, //开启显示数值
                  position: 'top', //数值在上方显示
                  textStyle: {  //数值样式
                    color: '#00FFFF',   //字体颜色
                    fontSize: 12  //字体大小
                  }
                }
              }
            }
          },
          // {
          //   name: "各标段占比",
          //   type: "pie",
          //   radius: [30, 25],
          //   left: 180,
          //   top: -40,
          //   data: chartData.pieChartData,
          //   itemStyle: {
          //     normal: {
          //       color: linear,
          //     }
          //   }
          // }
        ]
      }
      this.safetyCheckChart.instance.clear()
      this.safetyCheckChart.instance.setOption(option)
    },
  }
}
</script>
<style lang="less" scoped>
.dts-dialog {
  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    padding: 2px !important;
    background: rgba(0, 0, 0, .5) !important;
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
    // backdrop-filter: blur(3px); //模糊
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {
    padding-bottom: 10px;
  }
}
</style>