<template>
  <vxe-modal width="400" :position="{top: 10, left: 10}" class="dts-dialog" :lockView="false" :mask="false" v-model="visible" v-on="$listeners">
    <span slot="title" class="dts-dialog-title">
      <i class="el-icon-location-outline"></i>
      <div>{{planEndDate}}</div>
    </span>
    <div class="dts-dialog-body">
      <vxe-table
        ref="vxeTbl"
        height="70"
        size="mini"
        show-overflow
        :show-header="false"
        :column-config="{resizable: true}"
        :data="tableData"
        :border="false"
        :row-class-name="rowClassName"
        :scroll-y="{enabled: true, gt: 0}">
        <vxe-column field="name" title="名称" tree-node></vxe-column>
      </vxe-table>
      <div id="lineChart" :style="{width: '380px', height: '240px', 'padding-top': '10px'}"></div>
    </div>
  </vxe-modal>
</template>

<script>
// 进度模拟相关的分部分项工程窗口
export default {
  data () {
    return {
      tableData: [],
      planEndDate: '',
      xAxisData: [],// 曲线图x轴显示的日期
      visible: false,
      lineChart: {
        instance: null
      },
      option: {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          textStyle:{
            color: '#ffffff'//字体颜色
          },
          // data: []
        },
        grid: {// 控制上下左右边距
          x: 5,
          y: 25,
          x2: 5,
          y2: 5,
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: [],
          axisLabel: {
            show: true,
            textStyle: {
              color: "#ffffff",
            }
          },
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: false,
            formatter: '{value}',
            textStyle: {
              color: "#fff",
              margin: 15
            }
          }
        },
        series: [
          {
            name: '产值（万）',
            type: 'line',
            data: [],
            itemStyle: {
              normal: {
                color: "#66CC33",
                label: {
                  show: true, //开启显示数值
                  position: 'top', //数值在上方显示
                  textStyle: {  //数值样式
                    color: '#FFFFFF',   //字体颜色
                    fontSize: 12  //字体大小
                  }
                }
              }
            },
            smooth: true
          },
        ]
      },
      currentRowIndex: 0,
    }
  },
  methods: {
    init () {
      this.visible = true
      this.tableData = []
      this.planEndDate = ''
      this.$nextTick(() => {
        this.lineChart.instance = this.$echarts.init(document.getElementById('lineChart'))
        if (this.lineChart.instance) {
          this.lineChart.instance.clear()
          this.option.xAxis.data = [] // x轴显示的日期
          this.option.series[0].data = []
          this.lineChart.instance.setOption(this.option)
        }
      })
    },
    /**
     * 进度模拟过程中同步显示wbs和产值曲线
     * @param {*} planEndDate 日期
     * @param {*} wbsArr wbs名称（数组）
     * @param {*} cost1 产值（按日期累计）
     */
    showWbs(planEndDate, wbsArr, cost1) {
      if (wbsArr && wbsArr.length > 0) {
        let timer = null
        wbsArr.forEach(item => {
          if (item && item.length > 0) {
            this.tableData.push({
              name: item
            })
            this.currentRowIndex = this.tableData.length - 1
            this.$nextTick(() => {
              this.scrollDown()
              // if (timer) {
              //   window.clearTimeout(timer)
              // }
              timer = window.setTimeout(()=>{
                this.currentRowIndex = 0
                this.tableData.shift()
              }, 2000)
            })
          }
        })
      }
      this.planEndDate = planEndDate
      // 曲线
      this.option.xAxis.data.push(planEndDate) // x轴显示的日期
      this.option.series[0].data.push(cost1)
      this.lineChart.instance.setOption(this.option)
    },
    rowClassName({rowIndex}) {
      if (rowIndex == this.currentRowIndex) {
        return 'row-highlight'
      } else {
        return 'row-others'
      }
    },
    // 滚动条滚动到底部
    scrollDown() {
      const el = this.$refs.vxeTbl.$el.querySelector('.vxe-table--body-wrapper')
      if (el) {
        el.scrollTop = el.scrollHeight
      }
    }
  }
}
</script>
<style lang="less" scoped>
.dts-dialog {
  ::v-deep .vxe-table--render-wrapper {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent !important;
    color: white;
  }
  ::v-deep .vxe-modal--box {
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    background: rgba(0, 0, 0, .5) !important;
    // border: rgb(40, 118, 193) 1px solid;
    // backdrop-filter: blur(3px); //模糊
    border-radius: 15px;
    border: rgb(255, 255, 255) 1px solid;
  }

  ::v-deep .vxe-modal--header {
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent !important;
  }

  ::v-deep .vxe-modal--content {
    cursor: pointer;
    // background: rgba(0, 0, 0, .5) !important;
    background: transparent !important;
    padding: 5px 2px;
  }

  ::v-deep .vxe-modal--close-btn {
    color: white;
  }

  .dts-dialog-title {
    display: flex;
    color: white;
    align-items: center;

    div {
      padding-left: 6px;
    }

    button {
      position: absolute;
      top: 17px;
      right: 59px;
    }
  }

  .dts-dialog-body {
    ::v-deep .vxe-table {
      background: transparent !important;
      //  border: rgb(40, 118, 193) 1px solid;
      border-bottom: none;
    }

    ::v-deep .vxe-table--body {
      background: transparent !important;
    }
    
    ::v-deep .vxe-cell--label {
      font-size: 12px;
      color: #fff;
      text-align: center;
      background: transparent !important;
      //  border: rgb(40, 118, 193) 1px solid;
    }

    ::v-deep .vxe-body--row:hover {
      background-color: #006699 !important;
    }

    ::v-deep .vxe-body--row.row-highlight {
      background-color: #3333CC !important;
      font-weight: bold;
    }

    ::v-deep .vxe-body--row.row-others {
      background: transparent !important;
    }

    ::v-deep .row--current {
      background-color: #0066CC !important;
    }

    ::v-deep .vxe-tree--node-btn {
      color: #fff;
      background: transparent !important;
    }
  }
}
</style>