import { render, staticRenderFns } from "./dts-dashboard.vue?vue&type=template&id=6f77c355&scoped=true"
import script from "./dts-dashboard.vue?vue&type=script&lang=js"
export * from "./dts-dashboard.vue?vue&type=script&lang=js"
import style0 from "./dts-dashboard.vue?vue&type=style&index=0&id=6f77c355&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f77c355",
  null
  
)

export default component.exports